import React from "react";

export default function RatingField({ record, source, color = "red" }) {
  if (!record) return null;

  const porcentagem = record[source];

  if (!porcentagem || porcentagem <= 0) return null;

  const width = 12;
  const rating = (porcentagem * width) / 100;

  return (
    <div
      style={{
        display: "flex",
        width: `${width}em`,
      }}
    >
      <div
        style={{
          width: `${rating}em`,
          height: "22px",
          background: color,
          color: "white",
          marginRight: "4px",
        }}
      />
      <strong>{porcentagem}%</strong>
    </div>
  );
}
