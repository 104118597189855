import React from "react";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import MovimentoEdit from "./MovimentoEdit";
import MovimentotList from "./MovimentotList";
import { caixasEnable } from "../../permissionsHelper";

const movimento = {
  getResource: (p, a) => {
    return caixasEnable(p, a)
      ? {
          name: "Movimentos",
          list: MovimentotList,
          edit: MovimentoEdit,
        }
      : null;
  },
  getMenu: (p, a) => {
    return caixasEnable(p, a)
      ? {
          key: "Movimentos",
          to: "/Movimentos",
          primaryText: "Movimentos",
          leftIcon: <FormatListNumberedIcon />,
        }
      : null;
  },
};

export default movimento;
export const IconMovimento = FormatListNumberedIcon;
