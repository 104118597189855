import React, { useState } from "react";
import { Loading, Error, Title, useAuthenticated } from "react-admin";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Box,
  Stack,
  Button,
} from "@mui/material";
import useFetch from "../../dataProvider/useFetch";
import { withUser } from "../../common/util/hocs";
import { formatNumber, formatPhone } from "../../common/util/formatter";
import Decimal from "decimal.js";
import useCheckMobileScreen from "../../common/useCheckMobileScreen";
import RefreshIcon from "@material-ui/icons/Refresh";

const CardTotal = ({
  label,
  value,
  color = "#283593",
  component,
  isMobile,
}) => {
  return (
    <Card
      sx={{
        border: "1px solid #c5c5c5",
        borderBottom: `7px solid ${color}`,
        display: "flex",
        alignItems: "center",
        pr: isMobile ? 0 : 2,
        pl: isMobile ? 0 : 2,
      }}
    >
      <CardContent>
        {label && typeof label === "string" && (
          <Typography variant="subtitle1" color="textPrimary">
            {label}
          </Typography>
        )}
        {value && typeof value === "string" && (
          <Typography sx={{ fontWeight: "bold" }} variant="h5">
            {value}
          </Typography>
        )}
        {!!component && component}
      </CardContent>
    </Card>
  );
};

const CardComanda = ({ comanda, isMobile }) => {
  const {
    cod_cmd,
    identificador_cmd,

    nome_entregador,

    nome_cli,
    cpf_cli,
    tel_cli,
    cep_cli,
    endereco_cli,
    num_end_cli,
    bairro_cli,
    cidade_cli,

    total_alt_ped,
    servico_vlr_ped,
    total,

    itens,
    pagamentos,
  } = comanda;

  return (
    <Card sx={{ width: isMobile ? "100%" : 380 }}>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography gutterBottom variant="h2" component="span">
            {cod_cmd}{" "}
          </Typography>
          <Typography variant="h5" color="text.secondary" component="span">
            {identificador_cmd}
          </Typography>
        </Stack>

        {nome_cli && (
          <Typography variant="h6" color="text.secondary">
            {nome_cli}
            <br />
            {cpf_cli}
            <br />
            {formatPhone(tel_cli)}
          </Typography>
        )}

        {endereco_cli && (
          <Typography variant="h6" color="text.secondary">
            {endereco_cli}, {num_end_cli}
            <br />
            {bairro_cli} - {cidade_cli} - {cep_cli}
          </Typography>
        )}

        {nome_entregador && (
          <Typography variant="h6" color="text.secondary">
            Entregador: {nome_entregador}
          </Typography>
        )}
        <ListItens
          itens={itens}
          pagamentos={pagamentos}
          total_alt_ped={total_alt_ped}
          servico_vlr_ped={servico_vlr_ped}
          total={total}
        />
      </CardContent>
    </Card>
  );
};

const ListItens = ({
  itens,
  pagamentos,
  total_alt_ped,
  servico_vlr_ped,
  total,
}) => {
  const totalPago = pagamentos.sum("valor");
  const diff = Decimal(total).minus(totalPago);
  const troco = diff.lessThan(0);
  const color = troco ? "green" : "red";

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Qtd.</TableCell>
          <TableCell>Produto</TableCell>
          <TableCell align="right">Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {itens.map((i) => (
          <TableRow key={i.id}>
            <TableCell component="th" scope="row">
              {i.qtd_it}
            </TableCell>
            <TableCell>{i.desc_prod}</TableCell>
            <TableCell align="right">
              {formatNumber(i.total_alt_it, 2)}
            </TableCell>
          </TableRow>
        ))}

        <TableRow key="space-1" sx={{ "td, th": { border: 0 } }}>
          <TableCell colSpan={3} sx={{ padding: 1 }} />
        </TableRow>

        <TableRow key="total-produtos">
          <TableCell align="right" colSpan={2}>
            <strong>Total dos Produtos</strong>
          </TableCell>
          <TableCell align="right">
            <strong>{formatNumber(total_alt_ped, 2)}</strong>
          </TableCell>
        </TableRow>

        {servico_vlr_ped > 0 && (
          <TableRow key="total-produtos">
            <TableCell align="right" colSpan={2}>
              <strong>Total do Serviço</strong>
            </TableCell>
            <TableCell align="right">
              <strong>{formatNumber(servico_vlr_ped, 2)}</strong>
            </TableCell>
          </TableRow>
        )}

        {servico_vlr_ped > 0 && (
          <TableRow key="total-produtos">
            <TableCell align="right" colSpan={2}>
              <strong>Total</strong>
            </TableCell>
            <TableCell align="right">
              <strong>{formatNumber(total, 2)}</strong>
            </TableCell>
          </TableRow>
        )}

        <TableRow key="space-1" sx={{ "td, th": { border: 0 } }}>
          <TableCell colSpan={3} sx={{ padding: 1 }} />
        </TableRow>

        {pagamentos.map((p) => (
          <TableRow key={p.id}>
            <TableCell align="right" colSpan={2}>
              {p.desc_moeda}
            </TableCell>
            <TableCell align="right">{formatNumber(p.valor, 2)}</TableCell>
          </TableRow>
        ))}

        <TableRow key="space-1" sx={{ "td, th": { border: 0 } }}>
          <TableCell colSpan={3} sx={{ padding: 1 }} />
        </TableRow>

        {pagamentos.length > 0 && (
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="right" colSpan={2} sx={{ color }}>
              <strong>{troco ? "Troco" : "Falta"}</strong>
            </TableCell>
            <TableCell align="right" sx={{ color }}>
              <strong>{formatNumber(diff, 2)}</strong>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const PdvLocalList = ({ loja, ...rest }) => {
  useAuthenticated();
  const [filtro, setFiltro] = useState("");
  const { data, isLoading, error, refetch } = useFetch(
    "PDV_local/comandasAbertas",
    {
      lojaId: loja.id,
    }
  );
  const isMobile = useCheckMobileScreen();

  if (error) {
    return (
      <>
        <Title title="PDV Vendas Abertas" />
        <Error error={error} />
      </>
    );
  }

  if (isLoading || !data) {
    return (
      <>
        <Title title="PDV Vendas Abertas" />
        <Loading />
      </>
    );
  }

  function filterShow(comanda, filtro) {
    const { cod_cmd, identificador_cmd, nome_cli, cpf_cli, tel_cli } = comanda;

    return (
      cod_cmd.toString() === filtro ||
      (identificador_cmd &&
        identificador_cmd.removeAccentsToLower().includes(filtro)) ||
      (nome_cli && nome_cli.removeAccentsToLower().includes(filtro)) ||
      (cpf_cli && cpf_cli.removeAccentsToLower().includes(filtro)) ||
      (tel_cli && tel_cli.removeAccentsToLower().includes(filtro))
    );
  }

  const comandas = filtro
    ? data.comandas.filter((c) => filterShow(c, filtro.removeAccentsToLower()))
    : data.comandas;

  const dataToSum = data.comandas.map((c) => ({
    total: c.total,
    pessoas: c.numero_pessoas_cmd ? c.numero_pessoas_cmd : 1,
  }));

  const dataSummarized = dataToSum.sum(["total", "pessoas"]);

  const getLastUpdate = () => {
    if (!data || !data.atualizado_em) {
      return "nunca";
    }

    const endTime = new Date(data.atualizado_em.time);
    const diffMilisec = new Date().getTime() - endTime.getTime();
    const diffMinute = Math.round(diffMilisec / 60000);
    return `${diffMinute} min`;
  };

  return (
    <>
      <Title title="PDV Vendas Abertas" />
      <Box margin={2}>
        <Grid
          container
          spacing={isMobile ? 1 : 6}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={isMobile ? 12 : 0}>
            <Button
              variant="outlined"
              onClick={(e) => refetch()}
              sx={{ width: isMobile ? "100%" : 200, textTransform: "none" }}
            >
              <RefreshIcon />
              <Typography paddingLeft={1}>
                {`Atualizado ha: ${getLastUpdate()}`}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <CardTotal
              value={dataToSum.length.toString()}
              label={`Quant.`}
              isMobile={isMobile}
            />
          </Grid>
          <Grid item>
            <CardTotal
              value={dataSummarized.pessoas.toString()}
              label={`Pessoas`}
              isMobile={isMobile}
              color="green"
            />
          </Grid>
          <Grid item>
            <CardTotal
              value={formatNumber(dataSummarized.total)}
              label={`Total R$`}
              isMobile={isMobile}
              color="red"
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 0}>
            <TextField
              label="Filtro"
              value={filtro}
              onChange={(event) => setFiltro(event.target.value)}
              fullWidth
              sx={{ marginY: 2 }}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        {comandas.map((c) => (
          <Grid item>
            <CardComanda comanda={c} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default withUser(PdvLocalList);
