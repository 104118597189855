import React, { useState } from "react";
import { Title, useAuthenticated } from "react-admin";
import { Box, Card, CardHeader, CardContent, Stack, Grid } from "@mui/material";
import Decimal from "decimal.js";
import { Bar } from "react-chartjs-2";
import { fetchJSON } from "../dataProvider";
import UXDelay from "../dataProvider/uxdelay";
import { useStyles } from "./ReportDailyInvoicings/styles";
import isEmpty from "lodash.isempty";
import Search from "./Search";
import { formatCurrency } from "../common/util/formatter";
import { withUser } from "../common/util/hocs";
import ReportTotalCard from "./ReportTotalCard";
import { reportPDVEnable } from "../permissionsHelper";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

const ReportNFe = ({ usuario, loja, ...props }) => {
  useAuthenticated();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  async function retrieveReportData({ startDate, endDate, nfe }) {
    setLoading(true);
    try {
      const newData = await UXDelay(
        fetchJSON("Relatorio/nfe/totais", null, {
          lojaId: loja.id,
          startDate,
          endDate,
          nfesEmitidas: nfe === "NFe Emitida",
        })
      );

      setData(newData);
    } catch (err) {
      console.log(JSON.stringify(err));
    } finally {
      setLoading(false);
    }
  }

  const currencyOptions = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
          callback: function (value) {
            return "R$ " + value;
          },
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          callback: function (value) {
            return this.getLabelForValue(value).substring(0, 20);
          },
        },
      },
    },
    indexAxis: "y",
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: "end",
        align: "left",
        color: "white",
        formatter: function (value, context) {
          return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(value);
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.x !== null) {
              label += new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(context.parsed.x);
            }
            return label;
          },
        },
      },
    },
  };

  const dataEmpty = isEmpty(data) || Decimal(data?.total ?? 0).isZero();
  const size = dataEmpty ? 0 : data.totalPorFornecedor.length;
  const chartHeight = Math.max(600, size * 30);

  return (
    <Card className={classes.root}>
      <Title title="Total de NFe's" />
      <CardContent>
        <Search
          fields={["dateRange", "timeRange", "nfe"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <div>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
              sx={{ my: 5 }}
            >
              <Grid item xs={3}>
                <ReportTotalCard
                  total={data.total}
                  icon={AttachMoneyIcon}
                  type="currency"
                  title={"Total"}
                  color={"#36A2EB"}
                />
              </Grid>
            </Grid>

            <Stack spacing={5}>
              <Grid container>
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <CardHeader
                      className={classes.cardHeader}
                      title={"Total de NFe's Autorizadas por fornecedor"}
                    />
                    <CardContent>
                      <Box height={chartHeight}>
                        <Bar
                          data={getBarChartData(data)}
                          options={currencyOptions}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Stack>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

function getBarChartData(data) {
  const labels = data.totalPorFornecedor.map((i) => i.fornecedor_nome);
  const values = data.totalPorFornecedor.map((i) => i.total);
  return {
    labels: labels,
    datasets: [
      {
        label: "Total",
        yAxisID: "y",
        data: values,
        fill: false,
        borderColor: "#36A2EB",
        backgroundColor: "#36A2EB",
        tension: 0.3,
        datalabels: {
          display: "auto",
          align: "center",
          anchor: "top",
          formatter: (value, _) => formatCurrency(value),
          color: "black",
          borderRadius: 4,
          padding: 6,
          font: {
            weight: "bold",
          },
        },
      },
    ],
  };
}

export default withUser(ReportNFe);

export const reportNFe = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "relatorioNFe",
          to: "/relatorio/nfe",
          primaryText: "NFe Totais",
        }
      : null,
};
