import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

function ClassificacaoList(props) {
  return (
    <List {...props} exporter={false}>
      <Datagrid>
        <TextField source="nome" />
        <TextField label="Tipo" source="tipo" />
        <TextField label="DRE" source="dre" />
        <EditButton />
      </Datagrid>
    </List>
  );
}

export default ClassificacaoList;
