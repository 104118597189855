import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatNumber } from "../../common/util/formatter";
import Decimal from "decimal.js";
import { Link } from "react-router-dom";
import DeletadoPorDetalhes from "./DeletadoPorDetalhes";
import { TransactionAcquireIcon } from "../transaction_pos/TransactionAquire";
import CurrencyCentsField from "../../common/form/CurrencyCentsField";
import MoedaAppIconText from "../moeda_app/MoedaAppIconText";
import TransactionPosStatus from "../transaction_pos/TransactionPosStatus";
import {
  TextField,
  ReferenceManyField,
  Datagrid,
  DateField,
} from "react-admin";

const ListItens = ({
  produtos,
  recebimentos,
  total_bruto,
  total_desconto,
  total_servico,
  servico,
  movimentoNaoDeletado,
}) => {
  const total = Decimal(total_bruto)
    .plus(total_desconto)
    .plus(servico ? total_servico : 0)
    .toNumber();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Codigo.</TableCell>
          <TableCell>Produto</TableCell>
          <TableCell>Qtd.</TableCell>
          <TableCell align="right">R$ Unit.</TableCell>
          <TableCell align="right">Total</TableCell>
          <TableCell align="right">Desconto</TableCell>
          <TableCell align="right">Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {produtos.map((i) => (
          <TableRow key={i.id} className={i.deletadoPorId ? `cancelado` : ""}>
            <TableCell component="th" scope="row">
              {i.produtoId ? (
                <Link to={`/produtos/${i.produtoId}`}>{i.cod_prod}</Link>
              ) : (
                ""
              )}
            </TableCell>
            <TableCell>
              {i.descricao}
              {movimentoNaoDeletado && <DeletadoPorDetalhes record={i} />}
            </TableCell>
            <TableCell>{i.qtd}</TableCell>
            <TableCell align="right">
              {formatNumber(i.valor_unitario, 2)}
            </TableCell>
            <TableCell align="right">
              {formatNumber(i.valor_bruto, 2)}
            </TableCell>
            <TableCell align="right">
              {formatNumber(i.valor_desconto, 2)}
            </TableCell>
            <TableCell align="right">
              {formatNumber(
                Decimal(i.valor_bruto).minus(i.valor_desconto).toNumber(),
                2
              )}
            </TableCell>
          </TableRow>
        ))}

        <TableRow key="total-produtos">
          <TableCell align="right" colSpan={5}>
            <strong>Total dos Produtos</strong>
          </TableCell>
          <TableCell align="right" colSpan={2}>
            <strong>{formatNumber(total_bruto, 2)}</strong>
          </TableCell>
        </TableRow>

        {total_servico !== 0 && (
          <TableRow
            key="total-servico"
            sx={
              servico
                ? {}
                : { textDecorationLine: "line-through", fontStyle: "italic" }
            }
          >
            <TableCell align="right" colSpan={5}>
              <strong>{servico ? "Serviço" : "Não Pagou Serviço"}</strong>
            </TableCell>
            <TableCell align="right" colSpan={2}>
              <strong>{formatNumber(total_servico, 2)}</strong>
            </TableCell>
          </TableRow>
        )}

        {total_desconto !== 0 && (
          <TableRow key="total-desconto">
            <TableCell align="right" colSpan={5}>
              <strong>Desconto</strong>
            </TableCell>
            <TableCell align="right" colSpan={2}>
              <strong>{formatNumber(total_desconto, 2)}</strong>
            </TableCell>
          </TableRow>
        )}

        {total !== total_bruto && (
          <TableRow key="total-final">
            <TableCell align="right" colSpan={5}>
              <strong>Total</strong>
            </TableCell>
            <TableCell align="right" colSpan={2}>
              <strong>{formatNumber(total, 2)}</strong>
            </TableCell>
          </TableRow>
        )}

        {recebimentos.map((r) => (
          <TableRow key={r.id} className={r.deletadoPorId ? `cancelado` : ""}>
            <TableCell align="right" colSpan={5}>
              {r.moeda.nome}
              {movimentoNaoDeletado && <DeletadoPorDetalhes record={r} />}
            </TableCell>
            <TableCell align="right" colSpan={2}>
              {formatNumber(r.valor, 2)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const MovimentoDetalhes = ({ record }) => {
  const { produtos, recebimentos } = record;

  if (!produtos || !recebimentos) {
    return null;
  }

  return (
    <Box sx={{ py: 5 }}>
      <Typography variant="h6" gutterBottom>
        Produtos
      </Typography>
      <ListItens
        produtos={produtos}
        recebimentos={recebimentos}
        total_bruto={record.total_bruto}
        total_desconto={record.total_desconto}
        total_servico={record.total_servico}
        servico={record.servico}
        movimentoNaoDeletado={!record.deletadoPorId}
      />

      <ReferenceManyField
        record={record}
        addLabel={true}
        label="Transações"
        reference="Transaction_pos"
        sort={{ field: "id", order: "DESC" }}
        target="movimentoId"
        fullWidth
      >
        <Datagrid>
          <TransactionAcquireIcon label="Adquirente" />
          <DateField source="date" label="Data" showTime={true} />
          <CurrencyCentsField source="amount" label="Valor" />
          <TextField source="type" label="Tipo" />
          <MoedaAppIconText source="cardBrand" label="Bandeira" />
          <TransactionPosStatus label="Status" />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  );
};

export default MovimentoDetalhes;
