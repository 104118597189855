import React from "react";
import {
  FormDataConsumer,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { IdDates } from "../../common/form/ConditionalInputs";
import { Box, Typography } from "@mui/material";

const validateReq = [required()];

const DRE_DESPESA = [
  {
    id: "PRODUTO",
    name: "PRODUTO OU MATERIA PRIMA",
    help: `Produto para revenda, materia prima, isumos e embalagens`,
  },
  {
    id: "CUSTOS_VARIAVEIS",
    name: "CUSTOS VARIÁVEIS",
    help: `Imposto sobre as vendas, Taxa dos cartões`,
  },
  {
    id: "CUSTOS_FIXOS",
    name: "CUSTOS FIXOS",
    help: `Salários, Encargos sociais sobre salários, Pró-labore, Contador, Energia, Água, Aluguel, Manutenção, Segurança, Telefone, internet`,
  },
  { id: "INVESTIMENTOS", name: "INVESTIMENTOS E AMORTIZAÇÕES" },
];

const DREDespesa = ({ formData }) => {
  if (formData.tipo !== "DESPESA") {
    return null;
  }

  console.log(formData.dre);
  const selected = DRE_DESPESA.find((d) => d.id === formData.dre);

  return (
    <Box mb={1}>
      <SelectInput label="DRE" source="dre" choices={DRE_DESPESA} />
      {selected && selected.help && (
        <Typography>
          Exemplos: <br />
          {selected.help}
        </Typography>
      )}
    </Box>
  );
};

const ClassificacaoForm = (props) => (
  <SimpleForm {...props} redirect="list">
    <IdDates />
    <SelectInput
      label="tipo"
      source="tipo"
      choices={[
        { id: "RECEITA", name: "Receita" },
        { id: "DESPESA", name: "Despesa" },
      ]}
    />

    <FormDataConsumer>
      {(formDataProps) => <DREDespesa {...formDataProps} />}
    </FormDataConsumer>

    <TextInput source="nome" validate={validateReq} />
  </SimpleForm>
);

export default ClassificacaoForm;
