import React from "react";
import { Create } from "react-admin";
import FichaTecnicaForm from "./FichaTecnicaForm";

const FichaTecnicaCreate = (props) => (
  <Create title="Nova Ficha Técnica" {...props}>
    <FichaTecnicaForm />
  </Create>
);

export default FichaTecnicaCreate;
