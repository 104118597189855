import React from "react";
import { Datagrid, List } from "react-admin";
import NFeResumoFilter from "./NFeFilter";
import { NFeTipoField } from "./NFeTipo";
import NFeEmitente from "./NFeEmitente";
import NFeValorData from "./NFeValorData";
import NFeStatus from "./NFeStatus";
import NFeActions from "./NFeActions";
import NFeListActions from "./NFeListActions";

const NFeResumoList = (props) => (
  <List
    {...props}
    empty={false}
    bulkActionButtons={false}
    exporter={false}
    filters={<NFeResumoFilter />}
    actions={<NFeListActions />}
    sort={{ field: "dhEmi", order: "DESC" }}
  >
    <Datagrid>
      <NFeTipoField label="Tipo" />
      <NFeEmitente label="Emitente" />
      <NFeValorData label="Valor e Data" />
      <NFeStatus label="Status" />
      <NFeActions label="Ações" />
    </Datagrid>
  </List>
);

export default NFeResumoList;
