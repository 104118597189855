import React, { useState, useRef } from "react";
import { useAuthenticated } from "react-admin";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  processCsvFile,
  normalizeColumnsValues,
  checkRequiredFields,
} from "./csv-extractor";
import { Button } from "@material-ui/core";

import LoadingButton from "../LoadingButton";
import DownloadButton from "./DownloadButton";
import Table from "../table/Table";
import { save } from "../../dataProvider";
import { withUser } from "../util/hocs";

function ImportarAreasEntrega(props) {
  useAuthenticated();

  const tableRef = useRef();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  const columnsAll = [
    { title: "Bairro", field: "bairro", type: "string" },
    { title: "Entrega", field: "status", type: "boolean" },
    { title: "Distancia", field: "distancia", type: "numeric" },
    { title: "Valor", field: "valor", type: "numeric", required: true },
    { title: "Minutos", field: "minutos", type: "numeric", required: true },
  ];

  const onFileAdded = async (e) => {
    const file = e.target.files && e.target.files[0];
    try {
      const values = await processCsvFile(file);

      if (values.length > 0) {
        const data = normalizeColumnsValues(values, columnsAll);
        setColumns(data.columns);
        setRows(data.values);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const onSave = async () => {
    setLoading(true);
    let erros = [];
    for (const area of rows) {
      const a = {
        ...area,
        lojaId: props.loja.id,
      };
      delete a.tableData;

      try {
        checkRequiredFields(a, columnsAll);
        await save("loja_Entregas", a);
      } catch (err) {
        area.erro =
          err.body && err.body.error ? err.body.error.message : err.message;
        erros = rows.slice(rows.indexOf(area));
        break;
      }
    }

    setRows(erros);
    setLoading(false);
    if (erros.length > 0) {
      tableRef.current.onToggleDetailPanel(
        [0],
        tableRef.current.props.detailPanel[0].render
      );
    }
  };

  return (
    <Card>
      <Table
        tableRef={tableRef}
        title="Importar áreas de entrega"
        components={{
          Container: (innerProps) => (
            <Paper
              {...innerProps}
              className={props.containerClassName}
              elevation={0}
            />
          ),
        }}
        columns={columns}
        data={rows}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setRows([...rows, newData]);
              resolve();
            }),
          onRowUpdate: async (newData, oldData) => {
            let array = [...rows];
            const index = array.indexOf(oldData);
            array[index] = newData;
            setRows(array);
          },
          onRowDelete: async (oldData) => {
            let array = [...rows];
            const index = array.indexOf(oldData);
            array.splice(index, 1);
            setRows(array);
          },
        }}
        detailPanel={[
          {
            tooltip: "Erro",
            render: (rowData) => {
              return (
                <div
                  style={{
                    padding: 10,
                    color: "white",
                    backgroundColor: "#E53935",
                  }}
                >
                  {rowData.erro}
                </div>
              );
            },
          },
        ]}
      />
      <CardActions>
        <Button variant="contained" component="label" color="primary">
          <GetAppIcon />
          <span>Selecionar arquivo</span>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={onFileAdded}
            value={""}
            accept=".csv,.tsv,.txt"
          />
        </Button>
        <LoadingButton
          loading={loading}
          disabled={rows.length === 0}
          text="Salvar"
          variant="contained"
          component="label"
          color="primary"
          onClick={onSave}
        />
      </CardActions>
      <DownloadButton columns={columnsAll} fileName={"areasEntrega"} />
    </Card>
  );
}

export default withUser(ImportarAreasEntrega);
