import React, { useState, useRef } from "react";
import { useAuthenticated } from "react-admin";
import groupBy from "lodash/groupBy";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  processCsvFile,
  normalizeColumnsValues,
  checkRequiredFields,
} from "./csv-extractor";
import { Button } from "@material-ui/core";

import DownloadButton from "./DownloadButton";
import LoadingButton from "../LoadingButton";
import Table from "../table/Table";
import { fetchJSON, save } from "../../dataProvider";
import { withUser } from "../util/hocs";

function ImportarPerguntas(props) {
  useAuthenticated();

  const lojaId = props.loja.id;

  const tableRef = useRef();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  const columnsAll = [
    { title: "Pergunta", field: "descricao", type: "string", required: true },
    { title: "Qtd min", field: "qtd_min", type: "numeric", required: true },
    { title: "Qtd max", field: "qtd_max", type: "numeric", required: true },
    { title: "Repetir resposta", field: "repetir_resposta", type: "boolean" },

    { title: "Resposta", field: "nome", type: "string", required: true },
    { title: "Valor1", field: "valor", type: "numeric", required: true },
    { title: "Valor2", field: "valor2", type: "numeric" },
    { title: "Valor3", field: "valor3", type: "numeric" },
    { title: "Valor4", field: "valor4", type: "numeric" },

    {
      title: "Produtos",
      field: "produtos",
      type: "string",
      help: "cod_prod separado por virgula",
    },
  ];

  const onFileAdded = async (e) => {
    const file = e.target.files && e.target.files[0];
    try {
      const values = await processCsvFile(file);

      if (values.length > 0) {
        const data = normalizeColumnsValues(values, columnsAll);
        setColumns(data.columns);
        setRows(data.values);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const onSave = async () => {
    setLoading(true);

    let erros = [];
    const rowsGrouped = groupBy(rows, "descricao");
    const perguntas = [];
    const keys = Object.keys(rowsGrouped);
    keys.forEach((k) => {
      const rows = rowsGrouped[k];
      const p = {
        lojaId,
        descricao: rows[0].descricao,
        qtd_min: rows[0].qtd_min,
        qtd_max: rows[0].qtd_max,
        repetir_resposta: rows[0].repetir_resposta,
        respostas: rows.map((r) => ({
          cod_prod: r.cod_prod,
          nome: r.nome,
          valor: r.valor,
          valor2: r.valor2,
          valor3: r.valor3,
          valor4: r.valor4,
        })),
        produtosCodigos: rows[0].produtos,
      };
      perguntas.push(p);
    });

    for (const p of perguntas) {
      try {
        const allFields = p.respostas.map((r) => ({
          ...p,
          ...r,
        }));
        allFields.forEach((i) => checkRequiredFields(i, columnsAll));
        await save("pergunta", p).then(async (record) => {
          if (p.produtosCodigos) {
            const codigos = p.produtosCodigos.split(",");
            const codigosLoja = await fetchJSON("produtos", null, {
              filter: {
                fields: ["id"],
                where: { lojaId, cod_prod: { inq: codigos } },
              },
            });

            if (codigosLoja.length === 0) {
              return;
            }

            const newData = codigosLoja.map((prod) => ({
              produtoId: prod.id,
              perguntaId: record.id,
              lojaId,
            }));

            await fetchJSON("produto_pergunta", {
              method: "POST",
              body: JSON.stringify(newData),
            });
          }
        });
      } catch (err) {
        const pergunta = rows.find((r) => r.descricao === p.descricao);
        pergunta.erro =
          err.body && err.body.error ? err.body.error.message : err.message;
        erros = rows.slice(rows.indexOf(pergunta));
        break;
      }
    }

    setRows(erros);
    setLoading(false);
    if (erros.length > 0) {
      tableRef.current.onToggleDetailPanel(
        [0],
        tableRef.current.props.detailPanel[0].render
      );
    }
  };

  return (
    <Card>
      <Table
        tableRef={tableRef}
        title="Importar perguntas"
        components={{
          Container: (innerProps) => (
            <Paper
              {...innerProps}
              className={props.containerClassName}
              elevation={0}
            />
          ),
        }}
        columns={columns}
        data={rows}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setRows([...rows, newData]);
              resolve();
            }),
          onRowUpdate: async (newData, oldData) => {
            let array = [...rows];
            const index = array.indexOf(oldData);
            array[index] = newData;
            setRows(array);
          },
          onRowDelete: async (oldData) => {
            let array = [...rows];
            const index = array.indexOf(oldData);
            array.splice(index, 1);
            setRows(array);
          },
        }}
        detailPanel={[
          {
            tooltip: "Erro",
            render: (rowData) => {
              return (
                <div
                  style={{
                    padding: 10,
                    color: "white",
                    backgroundColor: "#E53935",
                  }}
                >
                  {rowData.erro}
                </div>
              );
            },
          },
        ]}
      />
      <CardActions>
        <Button variant="contained" component="label" color="primary">
          <GetAppIcon />
          <span>Selecionar arquivo</span>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={onFileAdded}
            value={""}
            accept=".csv,.tsv,.txt"
          />
        </Button>
        <LoadingButton
          loading={loading}
          disabled={rows.length === 0}
          text="Salvar"
          variant="contained"
          component="label"
          color="primary"
          onClick={onSave}
        />
      </CardActions>
      <DownloadButton columns={columnsAll} fileName={"perguntas"} />
    </Card>
  );
}

export default withUser(ImportarPerguntas);
