const exportExcelClipboard = (cols, datas) => {
  const colunas = [];
  const regex = /^\d{4}-\d{2}-\d{2}T/;
  for (const col of cols) {
    const field = col.field;
    const value0 = datas[0][field];
    const type = typeof value0;
    const func = col.render instanceof Function ? col.render.toString() : "";

    if (func.includes('currency: "BRL"')) {
      colunas.push({
        field: col.field,
        type: "numberString",
      });
      continue;
    }

    if (type === "string" && regex.test(value0)) {
      colunas.push({
        field: col.field,
        type: "dateString",
      });
      continue;
    }

    if (value0 instanceof Date) {
      colunas.push({
        field: col.field,
        type: "date",
      });
      continue;
    }

    colunas.push({
      field: col.field,
      type,
    });
  }

  // console.log(colunas);
  const lines = [];
  const cabecalho = cols.map((c) => c.title).join("\t");
  lines.push(cabecalho);

  for (const row of datas) {
    const line = [];
    for (const col of colunas) {
      const value = row[col.field];
      if (col.type === "date") {
        line.push(value.toLocaleString("pt-BR"));
      } else if (col.type === "dateString") {
        line.push(Date(value).toLocaleString("pt-BR"));
      } else if (col.type === "number") {
        line.push(value.toLocaleString("pt-BR"));
      } else if (col.type === "numberString") {
        line.push(Number(value).toLocaleString("pt-BR"));
      } else {
        line.push('"' + row[col.field] + '"');
      }
    }
    lines.push(line.join("\t"));
  }

  window.navigator.clipboard.writeText(lines.join("\r"));
};

export default exportExcelClipboard;
