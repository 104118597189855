import React, { useState, useRef } from "react";
import { useAuthenticated } from "react-admin";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  Button,
  Alert,
  AlertTitle,
  Paper,
  Card,
  CardActions,
  Stack,
} from "@mui/material";
import {
  processCsvFile,
  normalizeColumnsValues,
  checkRequiredFields,
  getNumbers,
} from "./csv-extractor";

import Table from "../table/Table";
import LoadingButton from "../LoadingButton";
import DownloadButton from "./DownloadButton";
import { save, getList, fetchJSON } from "../../dataProvider";
import { withUser } from "../util/hocs";
import { estoqueEnable } from "../../permissionsHelper";
import {
  OPCOES_ICMS_REGIME_NORMAL,
  OPCOES_ICMS_REGIME_SIMPLES_NACIONAL,
} from "../form/ICMSInput";
import { OPCOES_PIS } from "../form/PISInput";
import { OPCOES_COFINS } from "../form/COFINSInput";
import { OPCOES_IPI } from "../form/IPIInput";

function normCEST(str) {
  if (str > 0) {
    return str.padStart(7, "0");
  }

  return null;
}

function normNCM(str) {
  if (str > 0) {
    return str.padStart(8, "0");
  }

  return null;
}

function normCFOP(str) {
  if (str > 999 && str < 10000) {
    return str;
  }

  return null;
}

function normCST(str) {
  if (str) {
    return str.trim().padStart(2, "0");
  }

  return null;
}

function validaIPICST(str) {
  if (str) {
    const exist = OPCOES_IPI.some((o) => o.id === str);
    if (exist) {
      return str;
    }
  }

  return null;
}

function validaICMSCST(str) {
  const opcoes = [
    ...OPCOES_ICMS_REGIME_NORMAL,
    ...OPCOES_ICMS_REGIME_SIMPLES_NACIONAL,
  ];
  if (str) {
    const exist = opcoes.some((o) => o.id === str);
    if (exist) {
      return str;
    }
  }

  return null;
}

function validaPISCST(str) {
  if (str) {
    const exist = OPCOES_PIS.some((o) => o.id === str);
    if (exist) {
      return str;
    }
  }

  return null;
}

function validaCOFINSCST(str) {
  if (str) {
    const exist = OPCOES_COFINS.some((o) => o.id === str);
    if (exist) {
      return str;
    }
  }

  return null;
}

const columnsAll = [
  { title: "Id", field: "id", type: "numeric" },
  { title: "Codigo", field: "cod_prod", type: "numeric", required: true },
  { title: "Cod Aux", field: "cod_aux", type: "string" },
  { title: "Descricao", field: "desc_prod", type: "string", required: true },
  { title: "R$ Custo", field: "preco_cst_prod", type: "numeric" },
  {
    title: "R$ Venda",
    field: "preco_vnd_prod",
    type: "numeric",
    required: true,
  },
  { title: "Grupo", field: "grupo", type: "string" },
  { title: "Unidade", field: "unidade", type: "string" },
  { title: "Impressora", field: "impressora", type: "string" },
  { title: "Imposto", field: "imposto", type: "string" },
  {
    title: "NCM",
    field: "ncmId",
    type: "string",
    norm: [getNumbers, normNCM],
  },
  {
    title: "CEST",
    field: "cestId",
    type: "string",
    norm: [getNumbers, normCEST],
  },
  { title: "Estoque", field: "estoque_prod", type: "numeric" },

  { title: "R$ Venda 2", field: "preco_vnd2_prod", type: "numeric" },
  { title: "R$ Venda 3", field: "preco_vnd3_prod", type: "numeric" },
  { title: "R$ Venda 4", field: "preco_vnd4_prod", type: "numeric" },

  { title: "App mesa", field: "appMesa", type: "boolean" },
  { title: "App delivery", field: "appDelivery", type: "boolean" },
  { title: "App destaque", field: "appDestaque", type: "boolean" },
  { title: "App nome", field: "appNome", type: "string" },
  {
    title: "App R$ Venda",
    field: "appPreco",
    type: "numeric",
    required: true,
    default: 0,
  },
  { title: "App detalhes", field: "appDetalhes", type: "string" },
  { title: "App foto", field: "fotoUrl", type: "string" },

  {
    title: "Combo",
    field: "combo",
    type: "boolean",
    required: true,
    default: false,
  },
  {
    title: "Ponte",
    field: "ponte",
    type: "boolean",
    required: true,
    default: false,
  },

  { title: "Qtd Tab 2", field: "qtd_min_tab2", type: "numeric" },
  { title: "Qtd Tab 3", field: "qtd_min_tab3", type: "numeric" },
  { title: "Qtd Tab 4", field: "qtd_min_tab4", type: "numeric" },

  {
    title: "IPI CST",
    field: "ipi_cst",
    type: "string",
    norm: [normCST, validaIPICST],
  },
  { title: "IPI ALQ", field: "ipi_alq", type: "numeric" },
  {
    title: "ICMS CST",
    field: "icms_cst",
    type: "string",
    norm: [normCST, validaICMSCST],
  },
  { title: "ICMS ALQ", field: "icms_alq", type: "numeric" },
  { title: "ICMS MVA", field: "icms_st_mva", type: "numeric" },
  { title: "ICMS RED BC", field: "icms_red_bc", type: "numeric" },
  {
    title: "PIS CST",
    field: "pis_cst",
    type: "string",
    norm: [normCST, validaPISCST],
  },
  { title: "PIS ALQ", field: "pis_alq", type: "numeric" },
  {
    title: "COFINS CST",
    field: "cofins_cst",
    type: "string",
    norm: [normCST, validaCOFINSCST],
  },
  { title: "COFINS ALQ", field: "cofins_alq", type: "numeric" },
  {
    title: "CFOP Estadual",
    field: "cfop_estadual",
    type: "string",
    norm: [getNumbers, normCFOP],
  },
  {
    title: "CFOP Federal",
    field: "cfop_federal",
    type: "string",
    norm: [getNumbers, normCFOP],
  },

  { title: "Promo desconto", field: "promocao_desconto", type: "numeric" },
  { title: "Promo de", field: "promocao_de", type: "date" },
  { title: "Promo ate", field: "promocao_ate", type: "date" },
  { title: "Promo segunda", field: "dia_segunda", type: "boolean" },
  { title: "Promo terca", field: "dia_terca", type: "boolean" },
  { title: "Promo quarta", field: "dia_quarta", type: "boolean" },
  { title: "Promo quinta", field: "dia_quinta", type: "boolean" },
  { title: "Promo sexta", field: "dia_sexta", type: "boolean" },
  { title: "Promo sabado", field: "dia_sabado", type: "boolean" },
  { title: "Promo domingo", field: "dia_domingo", type: "boolean" },
  {
    title: "Promo condicoes",
    field: "promocao_todas_condicoes",
    type: "boolean",
  },

  { title: "Ativo", field: "stt_prod", type: "boolean" },
  { title: "Envia balança", field: "balanca_prod", type: "boolean" },
  { title: "Validade", field: "dias_vld_prod", type: "numeric" },
  { title: "Paga serviço", field: "paga_servico_prod", type: "boolean" },
  { title: "Adicional", field: "adicional", type: "boolean" },
  { title: "Matéria prima", field: "materia_prima_prod", type: "boolean" },
  { title: "Comissão", field: "comissao_prod", type: "numeric" },
  { title: "Desconto máximo", field: "dsct_max_prod", type: "numeric" },
  { title: "Estoque mínimo", field: "qtd_min_prod", type: "numeric" },
  { title: "Cor", field: "cor", type: "string" },
  { title: "Ordem", field: "ordem", type: "numeric" },
  { title: "Validade", field: "tam_emb_prod", type: "numeric" },
  { title: "Ingredientes", field: "ingredientes_prod", type: "string" },
  { title: "Código ANP", field: "codigo_anp", type: "string" },
  {
    title: "Código benefício fiscal",
    field: "codigo_beneficio",
    type: "string",
  },
  { title: "Escala relevante", field: "ind_escala", type: "string" },
  { title: "CNPJ fabricante", field: "cnpj_fab", type: "string" },
];

function ImportarProdutos(props) {
  useAuthenticated();
  const lojaId = props.loja.id;

  const tableRef = useRef();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onFileAdded = async (e) => {
    const file = e.target.files && e.target.files[0];
    try {
      const values = await processCsvFile(file);
      if (values.length > 0) {
        const data = normalizeColumnsValues(values, columnsAll);
        setColumns(data.columns);
        setRows(data.values);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const setRel = async (p, key, list, optional, getRel, imposto) => {
    if (imposto) {
      imposto = true;
    }
    const keyId = `${key}Id`;
    const existKey = p.hasOwnProperty(key);
    const existKeyId = p.hasOwnProperty(keyId);
    const keyValue = p[key];
    const newObj = getRel(p, list);

    let objRel = null;
    if (existKeyId) {
      const id = parseInt(p[keyId], 10);
      if (id > 0) {
        objRel = list.find((i) => i.id === id);
        if (!objRel) {
          throw new Error(`${key} com o id ${id} não existe!`);
        }
      }
    }

    if (!objRel && ((existKey && keyValue) || imposto)) {
      if (keyValue) {
        objRel = list.find(
          (i) => i.nome.toLowerCase() === keyValue.toLocaleLowerCase()
        );
      }

      if (!objRel) {
        if (newObj.id) {
          objRel = newObj;
        } else {
          newObj.lojaId = lojaId;
          objRel = await save(`${key}s`, newObj);
          list.push(objRel);
        }
      }
    }

    delete p[key];

    if (!objRel) {
      if (p.id || optional) {
        delete p[keyId];
        return;
      } else if (list.length === 0) {
        throw new Error(`${key} não cadastrado.`);
      }
    }

    p[keyId] = objRel ? objRel.id : list[0].id;
  };

  const getImposto = (p, list) => {
    const icms = props.loja.regime_tributario > 1 ? "60" : "500";

    const i = {
      icms_cst: p.icms_cst || icms,
      icms_alq: p.icms_alq || null,
      icms_st_mva: p.icms_st_mva || null,
      icms_red_bc: p.icms_red_bc || null,

      ipi_cst: p.ipi_cst || "  ",
      ipi_alq: p.ipi_alq || null,

      pis_cst: p.pis_cst || "49",
      pis_alq: p.pis_alq || null,

      cofins_cst: p.cofins_cst || "49",
      cofins_alq: p.cofins_alq || null,

      cfop_estadual: p.cfop_estadual,
      cfop_federal: p.cfop_federal,
    };

    if (!i.cfop_estadual) {
      i.cfop_estadual =
        i.icms_cst === "500" || i.icms_cst === "60" ? "5405" : "5102";
    }

    delete p.icms_cst;
    delete p.icms_alq;
    delete p.icms_st_mva;
    delete p.icms_red_bc;

    delete p.ipi_cst;
    delete p.ipi_alq;

    delete p.pis_cst;
    delete p.pis_alq;

    delete p.cofins_cst;
    delete p.cofins_alq;

    delete p.cfop_estadual;
    delete p.cfop_federal;

    const alqIsEqual = (alq1, alq2) => {
      const bothEmpty =
        (alq1 === undefined || alq1 === null) &&
        (alq2 === undefined || alq2 === null);
      const sameNumber =
        typeof alq1 === "number" && typeof alq2 === "number" && alq1 === alq2;
      return bothEmpty || sameNumber;
    };

    const impostoIsEqual = (e, i) => {
      return (
        e.icms_cst === i.icms_cst &&
        alqIsEqual(e.icms_alq, i.icms_alq) &&
        alqIsEqual(e.icms_st_mva, i.icms_st_mva) &&
        alqIsEqual(e.icms_red_bc, i.icms_red_bc) &&
        e.ipi_cst === i.ipi_cst &&
        alqIsEqual(e.ipi_alq, i.ipi_alq) &&
        e.pis_cst === i.pis_cst &&
        alqIsEqual(e.pis_alq, i.pis_alq) &&
        e.cofins_cst === i.cofins_cst &&
        alqIsEqual(e.cofins_alq, i.cofins_alq) &&
        e.cfop_estadual === i.cfop_estadual
      );
    };

    const objRel = list.find((e) => impostoIsEqual(e, i));

    if (!objRel) {
      i.nome = `${i.cfop_estadual} ${i.icms_cst} ${i.pis_cst} ${i.cofins_cst}`;
      return i;
    }

    return objRel;
  };

  const getGrupo = (p) => {
    const grupo = {
      nome: p.grupo,
      android: p.android,
      categoria: p.categoria,
      pizza: p.pizza,
      remove_prod: p.remove_prod,
      cor: p.grupo_cor,
      ordem: p.grupo_ordem,
    };

    delete p.grupo;
    delete p.android;
    delete p.categoria;
    delete p.pizza;
    delete p.remove_prod;
    delete p.grupo_cor;
    delete p.grupo_ordem;

    return grupo;
  };

  const getUnidade = (p) => {
    const unidade = {
      nome: p.unidade,
    };

    delete p.unidade;

    return unidade;
  };

  const getImpressora = (p) => {
    const impressora = {
      nome: p.impressora,
      modelo: p.modelo,
      endereco: p.endereco,
      velocidade: p.velocidade,
      raw: p.raw,
      cortaPapel: p.cortaPapel,
      abreGaveta: p.abreGaveta,
      beep: p.beep,
      colunas: p.colunas,
      colunasCondensado: p.colunasCondensado,
      linhasPular: p.linhasPular,
      impressoraReplica: p.impressoraReplica,
    };

    delete p.impressora;
    delete p.modelo;
    delete p.endereco;
    delete p.velocidade;
    delete p.raw;
    delete p.cortaPapel;
    delete p.abreGaveta;
    delete p.beep;
    delete p.colunas;
    delete p.colunasCondensado;
    delete p.linhasPular;
    delete p.impressoraReplica;

    return impressora;
  };

  const getChunks = (array) => {
    const chunkSize = 250;
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const checkExistAllColumn = async (field) => {
    const idCol = `${field}Id`;
    const ids = rows
      .map((r) => r[idCol])
      .filter((id) => id > 0)
      .filter((v, i, a) => a.indexOf(v) === i); //distinct

    const promises = getChunks(ids).map((chunkIds) =>
      fetchJSON(`${field}s/list`, null, {
        filter: { where: { id: { inq: chunkIds } }, fields: ["id"] },
      })
    );

    const array = await Promise.all(promises).then((results) =>
      results.reduce((acc, result) => [...acc, ...result], [])
    );

    const idsExists = array.map((i) => i.id);
    const idsMissing = ids.filter((id) => !idsExists.includes(id));
    if (idsMissing.length > 0) {
      throw new Error(`${field}s inválidos: ${idsMissing.join(", ")}`);
    }
  };

  const checkExistProduto = async () => {
    let error = "";
    const produtos = await fetchJSON("produtos", null, {
      filter: {
        where: { lojaId },
        fields: ["id", "cod_prod", "cod_aux"],
      },
    });

    const ids = rows.map((p) => p.id).filter((id) => id > 0);
    const idsExist = produtos.map((p) => p.id);
    const idsMissing = ids.filter((id) => !idsExist.includes(id));
    if (idsMissing.length > 0) {
      error = `Ids inválidos: ${idsMissing.join(", ")}`;
    }

    const codigos = [
      ...rows.map((p) => p.cod_prod),
      ...rows.map((p) => p.cod_aux).filter((aux) => !!aux),
    ];
    const codigosExist = [
      ...produtos.map((p) => p.cod_prod),
      ...produtos.map((p) => p.cod_aux).filter((aux) => !!aux),
    ].sort((a, b) => a - b);
    const codigosUsed = codigos.filter((c) => codigosExist.includes(c));
    if (codigosUsed.length > 0) {
      error += `\n\nCódigos em uso: ${codigosUsed.join(", ")}`;
    }

    if (error) {
      throw new Error(error.trim());
    }
  };

  const onSave = async () => {
    try {
      setMessage("");
      setLoading(true);
      const severCheck = await Promise.allSettled([
        checkExistProduto(),
        checkExistAllColumn("ncm"),
        checkExistAllColumn("cest"),
      ]);
      const severErros = severCheck
        .filter((r) => r.status === "rejected")
        .map((r) => r.reason.message)
        .join("\n\n");
      if (severErros) {
        throw new Error(severErros);
      }

      const grupos = (await getList("grupos", null, null, true)).data;
      const unidades = (await getList("unidades", null, null, true)).data;
      const impostos = (await getList("impostos", null, null, true)).data;
      const impressoras = (await getList("impressoras", null, null, true)).data;

      const produtosPendentes = await saveProducts(
        grupos,
        unidades,
        impostos,
        impressoras
      );

      setMessage(produtosPendentes.length > 0 ? produtosPendentes[0].erro : "");
      setRows(produtosPendentes);
    } catch (err) {
      console.log(JSON.stringify(err));
      setMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  const saveProducts = async (grupos, unidades, impostos, impressoras) => {
    const decNCM = rows[0].hasOwnProperty("ncmId");
    const decCusto = rows[0].hasOwnProperty("preco_cst_prod");

    let erros = [];
    let cadastrados = [];
    for (const produto of rows) {
      const id = produto.id;
      const update = id > 0;
      const p = { ...produto, id: null, lojaId };

      try {
        if (!update) {
          if (!decNCM) p.ncmId = 24022000;
          if (!decCusto) p.preco_cst_prod = 0;
        }

        checkRequiredFields(p, columnsAll, update);

        await setRel(p, "imposto", impostos, false, getImposto, true);
        await setRel(p, "grupo", grupos, false, getGrupo);
        await setRel(p, "unidade", unidades, false, getUnidade);
        await setRel(p, "impressora", impressoras, true, getImpressora);

        const newProd = await fetchJSON(
          update ? `produtos/${id}` : "produtos/save",
          {
            method: update ? "PATCH" : "POST",
            body: JSON.stringify(p),
          }
        );
        produto.id = newProd.id;
        cadastrados.push(produto);
      } catch (err) {
        console.error(err);
        console.log(JSON.stringify(err));
        produto.erro =
          err.body && err.body.error ? err.body.error.message : err.message;
        erros = rows.slice(rows.indexOf(produto));
        break;
      }
    }

    if (estoqueEnable(props.permissao, props.admin_user)) {
      const estoque = cadastrados.filter((p) => p.estoque_prod > 0);
      if (estoque.length > 0) {
        const inventario = {
          lojaId,
          nome: "Estoque inicial (Importação de produto)",
          itens: estoque.map((p) => ({
            produtoId: p.id,
            quantidade_estoque: p.estoque_prod,
          })),
        };
        await save(`inventarios`, inventario);
      }
    }

    return erros;
  };

  return (
    <Card>
      {message && (
        <Alert severity="error" sx={{ m: 2 }}>
          <AlertTitle>Erro</AlertTitle>
          <span style={{ whiteSpace: "pre-wrap" }}>{message}</span>
        </Alert>
      )}
      <Table
        tableRef={tableRef}
        title="Importar produtos"
        components={{
          Container: (innerProps) => (
            <Paper
              {...innerProps}
              className={props.containerClassName}
              elevation={0}
            />
          ),
        }}
        columns={columns}
        data={rows}
        editable={{
          onRowAdd: async (newData) => {
            setRows([...rows, newData]);
          },
          onRowUpdate: async (newData, oldData) => {
            const array = [...rows];
            array[oldData.tableData.index] = newData;
            setRows(array);
          },
          onRowDelete: async (oldData) => {
            let array = [...rows];
            array.splice(oldData.tableData.index, 1);
            setRows(array);
          },
        }}
        options={{ detailPanelType: "single" }}
        detailPanel={({ rowData }) => {
          const produto = rows[rowData.tableData.index];
          return (
            <div
              style={{
                padding: 10,
                color: "white",
                backgroundColor: "#E53935",
              }}
            >
              {produto.erro}
            </div>
          );
        }}
      />
      <CardActions sx={{ m: 1 }}>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Button variant="contained" component="label" color="primary">
            <GetAppIcon />
            <span>Selecionar arquivo</span>
            <input
              type="file"
              style={{ display: "none" }}
              onChange={onFileAdded}
              value={""}
              accept=".csv,.tsv,.txt"
            />
          </Button>
          <LoadingButton
            loading={loading}
            disabled={rows.length === 0}
            text="Salvar"
            variant="contained"
            component="label"
            color="primary"
            onClick={onSave}
          />
        </Stack>
      </CardActions>
      <DownloadButton columns={columnsAll} fileName={"produtos"} />
    </Card>
  );
}

export default withUser(ImportarProdutos);
