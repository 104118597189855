import React from "react";
import { FormDataConsumer, NumberInput, SimpleForm } from "react-admin";
import { IdDates } from "../../common/form/ConditionalInputs";
import FichaTecnicaGrid from "./FichaTecnicaGrid";
import { Grid, InputAdornment, Tooltip } from "@material-ui/core";
import ProdutoLazyReferenceInput from "../produto/ProdutoLazyReferenceInput";

const initialValues = {
  itens: [],
  total_custo: 0,
};

function ProdutoForm(props) {
  const { record } = props;
  const creating = !record || !record.id;
  const loaded = creating || record.loaded;

  return (
    <SimpleForm {...props} initialValues={initialValues}>
      <IdDates />

      <Grid container align="center" alignItems="center" spacing={2} fullWidth>
        <Grid item xs={10}>
          <ProdutoLazyReferenceInput
            record={record}
            label="Produto"
            source="produtoId"
            nameSource="produto"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip title="Preenchido automaticamente">
            <NumberInput
              variant="outlined"
              label="R$ Custo Total"
              source="total_custo"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
                readOnly: true,
              }}
            />
          </Tooltip>
        </Grid>
      </Grid>

      <FormDataConsumer>
        {({ formData }) => (
          <FichaTecnicaGrid loaded={loaded} formData={formData} />
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
}

export default ProdutoForm;
