import React from "react";
import MaterialTable from "@material-table/core";
import exportExcelClipboard from "./exportExcel";

const localization = {
  body: {
    emptyDataSourceMessage: "Nenhum registro localizado.",
    addTooltip: "Adicionar",
    deleteTooltip: "Remover",
    editTooltip: "Editar",
    editRow: {
      deleteText: "Remover este item?",
    },
  },
  header: {
    actions: "",
  },
  toolbar: {
    searchTooltip: "Pesquisar...",
    searchPlaceholder: "Pesquisar...",
  },
  pagination: {
    labelRowsSelect: "Registros",
    labelDisplayedRows: " {from}-{to} de {count}",
  },
};

function Table(props) {
  return (
    <MaterialTable
      {...props}
      localization={localization}
      options={{
        padding: "dense",
        headerStyle: {
          fontSize: `14px`,
          fontWeight: "bold",
          backgroundColor: "#d3d3d3",
          color: "#000000",
        },
        rowStyle: {
          fontSize: `12px`,
        },
        exportMenu: [
          {
            label: "Copiar para Excel",
            exportFunc: exportExcelClipboard,
          },
        ],
        ...props.options,
      }}
    />
  );
}

export default Table;
