import React from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";
import CurrencyCentsField from "../../common/form/CurrencyCentsField";
import MoedaAppIconText from "../moeda_app/MoedaAppIconText";
import TransactionPosStatus from "./TransactionPosStatus";
import TransactionPosFilter from "./TransactionPosFilter";
import { TransactionAcquireIcon } from "./TransactionAquire";

const TransactionPosList = (props) => (
  <List
    title="Lista de Transações"
    filters={<TransactionPosFilter />}
    {...props}
    sort={{ field: "id", order: "DESC" }}
    bulkActionButtons={false}
    exporter={false}
  >
    <Datagrid>
      <TextField source="movimentoId" label="Movimento id" />
      <TransactionAcquireIcon label="Adquirente" />
      <TextField source="nsu" label="NSU" />
      <DateField source="date" label="Data" showTime={true} />
      <CurrencyCentsField source="amount" label="Valor" />
      <TextField source="type" label="Tipo" />
      <TextField source="cardHolderNumber" label="Cartão" />
      <MoedaAppIconText source="cardBrand" label="Bandeira" />
      <TextField source="authorizationCode" label="Cod.Aut." />
      <TransactionPosStatus label="Status" />
    </Datagrid>
  </List>
);

export default TransactionPosList;
