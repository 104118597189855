import React, { useEffect, useState } from "react";
import { Edit } from "react-admin";
import FichaTecnicaForm from "./FichaTecnicaForm";
import { parseDataFichaTecnica } from "./calculos";

const WrappedFichaTecnicaForm = ({ record, ...rest }) => {
  const [arrays, setArrays] = useState({
    loaded: false,
    updatedAt: null,
    itens: [],
    total_custo: 0,
  });

  useEffect(() => {
    if (
      record.itens instanceof Array &&
      (arrays.loaded === false || record.updatedAt > arrays.updatedAt)
    ) {
      const parsedData = parseDataFichaTecnica(record);

      setArrays({
        loaded: true,
        updatedAt: record.updatedAt,
        itens: parsedData.itens,
        total_custo: parsedData.total_custo,
      });
    }
  }, [arrays, record]);

  return <FichaTecnicaForm {...rest} record={{ ...record, ...arrays }} />;
};

export default function FichaTecnicaEdit(props) {
  return (
    <Edit {...props}>
      <WrappedFichaTecnicaForm />
    </Edit>
  );
}
