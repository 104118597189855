import React from "react";
import { DateTimeInput, Filter, NumberInput, TextInput } from "react-admin";
import { TransactionAcquireInput } from "./TransactionAquire";

const TransactionStoneFilter = (props) => (
  <Filter {...props}>
    <TransactionAcquireInput label="Adquirente" alwaysOn />
    <DateTimeInput source="date_lte" label="Data" alwaysOn />
    <NumberInput source="amount" label="Valor (em centavos)" alwaysOn />
    <TextInput source="nsu" label="NSU" alwaysOn />
  </Filter>
);

export default TransactionStoneFilter;
