import React, { useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import LoadingButton from "../../common/LoadingButton";
import { cleanCache, fetchJSON } from "../../dataProvider";
import { HttpError, useNotify, useRefresh } from "react-admin";

const NFCeCancelarButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  if (!record.xmlUrl) {
    return null;
  }

  if (record.xmlUrl.includes("-inutili")) {
    return null;
  }

  if (record.xmlCancelamentoUrl) {
    return null;
  }

  const cancelarNFCe = async () => {
    try {
      setLoading(true);
      await fetchJSON("Sefaz/cancelaNFCe", {
        method: "POST",
        body: {
          lojaId: record.lojaId,
          movimentoId: record.movimentoId,
        },
      });

      cleanCache("NFCes");
      refresh();
    } catch (err) {
      console.log(JSON.stringify(err));

      if (err instanceof HttpError) {
        if (err.status === 400) {
          notify(err.message);
          return;
        }
      }

      notify("Ocorreu um erro ao cancelar a NFCe");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      color="error"
      text="Cancelar"
      loading={loading}
      icon={<ClearIcon />}
      onClick={cancelarNFCe}
    />
  );
};

export default NFCeCancelarButton;
