import React from "react";
import { Show } from "react-admin";
import FichaTecnicaForm from "./FichaTecnicaForm";

const FichaTecnicaEdit = (props) => (
  <Show title="Ficha Técnica" {...props}>
    <FichaTecnicaForm toolbar={false} />
  </Show>
);

export default FichaTecnicaEdit;
