import React from "react";
import { green, red, yellow, grey } from "@mui/material/colors";
import { Chip } from "@mui/material";

const NFCeStatus = (props) => {
  const { record } = props;
  if (!record) {
    return null;
  }

  const { cStat, xMotivo, xmlUrl, xmlCancelamentoUrl } = record;

  let cor, status;

  if (xmlCancelamentoUrl) {
    cor = red;
    status = "Cancelada";
  } else if (xmlUrl && xmlUrl.includes("-inutili")) {
    cor = yellow;
    status = "Inutilizada";
  } else if (xmlUrl) {
    cor = green;
    status = "Autorizada";
  } else {
    cor = grey;
    status = cStat ? `${cStat} - ${xMotivo}` : "Erro não identificado";
  }

  return (
    <Chip
      label={status}
      size="small"
      style={{
        margin: "2px",
        backgroundColor: cor[200],
        borderColor: cor[200],
      }}
    />
  );
};

export default NFCeStatus;
