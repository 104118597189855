import React, { useState } from "react";
import { Button } from "react-admin";
import LanguageIcon from "@material-ui/icons/Language";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { blue, green, yellow, red } from "@material-ui/core/colors";
import NFeCienciaOperacaoButton from "./NFeCienciaOperacaoButton";
import NFeConfirmacaoOperacaoButton from "./NFeConfirmacaoOperacaoButton";
import NFeDesconhecimentoOperacaoButton from "./NFeDesconhecimentoOperacaoButton";
import NFeOperacaoNaoRealizadaButton from "./NFeOperacaoNaoRealizadaButton";
import { NFeTipoField } from "./NFeTipo";
import NFeEmitente from "./NFeEmitente";
import NFeValorData from "./NFeValorData";
import { formatDateTime } from "../../common/util/formatter";

function NFeManifestarOperacaoButton({ record }) {
  const [open, setOpen] = useState(false);

  const handleOpenClick = () => setOpen(true);
  const handleCloseClick = () => setOpen(false);

  const operacoes = [
    {
      operacao: "Ciência da operação",
      explicacao:
        "Tenho ciência sobre esta operação, mas não possuo informações suficientes para apresentar minha manifestação conclusiva",
      status: record.cienciaOperacao,
      data: record.cienciaOperacaoData,
      button: <NFeCienciaOperacaoButton record={record} />,
      cor: blue,
    },
    {
      operacao: "Confirmação da operação",
      explicacao: "Confirmo a operação e o recebimento da mercadoria",
      status: record.confirmacaoOperacao,
      data: record.confirmacaoOperacaoData,
      button: <NFeConfirmacaoOperacaoButton record={record} />,
      cor: green,
    },
    {
      operacao: "Operação não realizada",
      explicacao:
        "Operação não foi realizada, por exemplo: recusa do recebimento da mercadoria",
      status: record.operacaoNaoRealizada,
      data: record.operacaoNaoRealizadaData,
      just: record.justificativa,
      button: <NFeOperacaoNaoRealizadaButton record={record} />,
      cor: yellow,
    },
    {
      operacao: "Desconhecimento da operação",
      explicacao: "Desconheço esta operação",
      status: record.desconhecimentoOperacao,
      data: record.desconhecimentoOperacaoData,
      button: <NFeDesconhecimentoOperacaoButton record={record} />,
      cor: red,
    },
  ];

  return (
    <>
      <Button label="Manifestar operação" onClick={handleOpenClick}>
        <LanguageIcon />
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleCloseClick}
      >
        <DialogTitle>NFe Manifestar Operação</DialogTitle>
        <DialogContent>
          <Box ml={2} mt={3} fullWidth>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{
                borderWidth: 2,
                borderRadius: 2,
                borderColor: "#eeeeee",
                borderStyle: "dashed",
                outline: "none",
              }}
              fullWidth
            >
              <Grid item xs>
                <NFeTipoField record={record} />
              </Grid>
              <Grid item xs={6}>
                <NFeEmitente record={record} />
              </Grid>
              <Grid item xs={4}>
                <NFeValorData record={record} />
              </Grid>
            </Grid>

            {operacoes
              .sort((a, b) => {
                return a.status && b.status
                  ? new Date(a.data) - new Date(b.data)
                  : a.status
                  ? -1
                  : 1;
              })
              .map((o) => (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  mt={3}
                  pb={3}
                  pt={2}
                  pr={2}
                  sx={{
                    borderWidth: 2,
                    borderRadius: 2,
                    borderColor: o.cor[500],
                    borderStyle: "solid",
                    outline: "none",
                    backgroundColor: o.cor[200],
                  }}
                  fullWidth
                >
                  <Grid item>
                    <Typography variant="h5">{o.operacao}</Typography>
                  </Grid>
                  {o.status && (
                    <Grid item>
                      <Typography>
                        Manifestado em: {formatDateTime(o.data)}
                      </Typography>
                    </Grid>
                  )}
                  {!o.status && <Grid item>{o.button}</Grid>}
                  <Grid item xs={12}>
                    <Typography variant="body2">{o.explicacao}</Typography>
                  </Grid>
                  {o.just && (
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        Justificativa: {o.just}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              ))}
          </Box>
          <DialogActions></DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default NFeManifestarOperacaoButton;
