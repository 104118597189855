import React from "react";
import {
  FormDataConsumer,
  NumberInput,
  FunctionField,
  required,
  minValue,
  NumberField,
  maxValue,
} from "react-admin";
import { Grid, Box, Typography, Avatar } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import yellow from "@material-ui/core/colors/yellow";
import { InputAdornment } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import Decimal from "decimal.js";
import Datagrid from "../../common/form/Datagrid/Datagrid";
import CurrencyField from "../../common/form/CurrencyField";
import { keyBy, get } from "lodash";
import { formatCurrency } from "../../common/util/formatter";
import CriaProdutoButton from "./NFeProdutoButtonCriar";
import ProcuraProdutoButton from "./NFeProdutoButtonProcurar";
import { calculateProfitMargin } from "../../common/util/util";
import NumberInputRemoveArrowsCSS from "../../common/form/NumberInputRemoveArrowsCSS.json";
import useChangeCalc from "./useChangeCalc";
import { validateDecimalPlaces } from "../../common/util/ra-validations";
import MargemUpDown from "./MargemUpDown";

const validateReq = [required()];
const validateFator = [
  required(),
  minValue(0.001),
  maxValue(99999999.9999),
  validateDecimalPlaces(4),
];
const validateVenda = [
  required(),
  minValue(0),
  maxValue(9999999999.99),
  validateDecimalPlaces(2),
];

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 12px 6px 12px",
    },
  },
  fatorInput: {
    width: "3em",
    ...NumberInputRemoveArrowsCSS,
  },
  margemInput: {
    width: "5.5em",
    ...NumberInputRemoveArrowsCSS,
  },
  vendaInput: {
    width: "7em",
    ...NumberInputRemoveArrowsCSS,
  },
});

const NFeProdutosGrid = (props) => {
  const changeCalc = useChangeCalc();
  const classes = useStyles();
  const {
    formData: { itens },
    loja,
  } = props;

  const rowStyle = (record, index) => ({
    backgroundImage: record.repetido
      ? `linear-gradient(to bottom right, ${
          itens[record.indexRepetidos[0]].color
        }, white)`
      : undefined,
  });

  return (
    <Datagrid
      rowStyle={rowStyle}
      classes={classes}
      loaded={itens.length > 0}
      data={keyBy(itens, "nItem")}
      ids={itens.map(({ nItem }) => nItem)}
      currentSort={{ field: "nItem", order: "ASC" }}
      fullWidth
    >
      <FunctionField
        label="Descrição e código"
        render={(record) => (
          <Box my={1} sx={{ maxWidth: "350px" }}>
            <Grid container alignItems="center" spacing={1}>
              {record.repetido && (
                <Grid item xs={1}>
                  <WarningIcon />
                </Grid>
              )}
              <Grid item xs={record.repetido ? 11 : 12}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography variant="body2">
                      <strong>{record.xProd}</strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    {record.cEAN === record.cEANTrib && (
                      <Typography variant="body2">{record.cEAN}</Typography>
                    )}
                    {record.cEAN !== record.cEANTrib && (
                      <Typography variant="body2">
                        {`${record.cEAN} / ${record.cEANTrib}`}
                      </Typography>
                    )}
                  </Grid>
                  {record.repetido && (
                    <Grid item>
                      <Typography variant="body2">
                        Produto repetido, atenção!
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      />

      <FunctionField
        label="Quantidade"
        textAlign="right"
        render={(record) => (
          <Typography variant="body2">
            {`${Decimal(record.qCom).toNumber()} ${record.uCom}`}
          </Typography>
        )}
      />

      <CurrencyField label="Total" source="vProdTotal" textAlign="right" />

      <FormDataConsumer label="Produto do estoque">
        {({ formData, record }) => {
          const { index, xProd } = record;
          const member = `itens[${index}]`;
          const item = get(formData, member);
          const produto = item.produto;
          const desc = produto.desc_prod === xProd ? "idem" : produto.desc_prod;
          return (
            <Grid container alignItems="center">
              <Grid item>
                <Typography variant="body2">
                  <strong>{desc}</strong>
                </Typography>
                <Box mt={1}>
                  <Typography variant="body2">
                    {(produto.cod_prod === null
                      ? "PROX.COD"
                      : produto.cod_prod) +
                      (produto.cod_aux ? ` / ${produto.cod_aux}` : "")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <CriaProdutoButton
                    loja={loja}
                    item={item}
                    member={member}
                    className={classes.buttonIcon}
                  />
                  <ProcuraProdutoButton
                    formData={formData}
                    member={member}
                    className={classes.buttonIcon}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </FormDataConsumer>

      <FormDataConsumer label="Fator">
        {({ record }) => (
          <NumberInput
            label=""
            variant="standard"
            source={`itens[${record.index}].fator`}
            onChange={changeCalc}
            helperText={""}
            validate={validateFator}
            className={classes.fatorInput}
          />
        )}
      </FormDataConsumer>

      <NumberField label="Estoque" source="estoque" textAlign="right" />

      <FormDataConsumer label={<ShuffleIcon />}>
        {({ formData, record }) => {
          const { index } = record;
          const produto = get(formData, `itens[${index}].produto`);
          if (!produto.id) {
            return (
              <Avatar sx={{ bgcolor: yellow[500], width: 56, height: 56 }}>
                <Typography variant="body2">
                  <strong>Novo</strong>
                </Typography>
              </Avatar>
            );
          }

          const custo = get(formData, `itens[${index}].custo`);
          const margem = calculateProfitMargin(
            produto.preco_cst_prod,
            custo,
            0
          );
          return <MargemUpDown diff={margem} />;
        }}
      </FormDataConsumer>

      <FormDataConsumer label="Custo" textAlign="right">
        {({ formData, record }) => {
          const { index } = record;
          const produto = get(formData, `itens[${index}].produto`);
          return (
            <>
              <Typography variant="body2" align="right">
                <strong>
                  {formatCurrency(formData.itens[index].custo, 2, 4)}
                </strong>
              </Typography>
              {produto.id && (
                <Box mt={1}>
                  <Typography variant="caption" align="right">
                    {formatCurrency(produto.preco_cst_prod, 2, 4)}
                  </Typography>
                </Box>
              )}
            </>
          );
        }}
      </FormDataConsumer>

      <FormDataConsumer label="Margem" textAlign="right">
        {({ formData, record }) => (
          <NumberInput
            label=""
            variant="standard"
            source={`itens[${record.index}].margem`}
            onChange={changeCalc}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            helperText={get(
              formData,
              `itens[${record.index}].margemHelperText`
            )}
            className={classes.margemInput}
            validate={validateReq}
          />
        )}
      </FormDataConsumer>

      <FormDataConsumer label="Venda" textAlign="right">
        {({ formData, record }) => (
          <NumberInput
            label=""
            variant="standard"
            source={`itens[${record.index}].venda`}
            onChange={changeCalc}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
            helperText={get(formData, `itens[${record.index}].vendaHelperText`)}
            className={classes.vendaInput}
            validate={validateVenda}
          />
        )}
      </FormDataConsumer>
    </Datagrid>
  );
};

export default NFeProdutosGrid;
