import { Error, useGetOne } from "react-admin";
import MovimentoDetalhes from "./MovimentoDetalhes";
import { Box } from "@mui/material";
import CircularProgress from "../../common/CircularProgress";

const MovimentoDetalhesDatagrid = ({ record }) => {
  const { data, isLoading, error } = useGetOne("Movimentos", record.id);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <Box marginX="20%" marginBottom={10}>
      <MovimentoDetalhes record={data} />
    </Box>
  );
};

export default MovimentoDetalhesDatagrid;
