import React, { useState } from "react";
import { Title, useAuthenticated, useNotify } from "react-admin";
import { Card, CardContent } from "@mui/material";
import { fetchJSON } from "../dataProvider";
import UXDelay from "../dataProvider/uxdelay";
import { useStyles } from "./ReportDailyInvoicings/styles";
import isEmpty from "lodash.isempty";
import Search from "./Search";
import { reportPDVEnable } from "../permissionsHelper";
import TableReport from "../common/table/TableReport";

const TableDRE = ({ data }) => {
  function getRowStyle(row) {
    const styles = {
      green: {
        backgroundColor: "#C8E6C9",
        fontWeight: 900,
      },
      red: {
        backgroundColor: "#FFAB91",
        fontWeight: 900,
      },
      yellow: {
        backgroundColor: "#EEE",
        fontWeight: 900,
      },
    };

    switch (row.prefix) {
      case "1":
        return styles.green;
      case "2":
      case "4":
      case "6":
        return styles.red;
      case "3":
      case "5":
      case "7":
        return styles.yellow;
      default:
        return null;
    }
  }

  function getValueOperation(row) {
    switch (row.prefix) {
      case "1":
        return "+";
      case "2":
      case "4":
      case "6":
        return "-";
      case "3":
      case "5":
      case "7":
        return "=";
      default:
        return null;
    }
  }

  return (
    <TableReport
      data={data}
      columns={[
        {
          title: "Nome",
          field: "name",
          sorting: false,
          render: (rowData) => (
            <span style={{ paddingLeft: `${rowData.level * 2}em` }}>
              {rowData.prefix} - {rowData.name}
            </span>
          ),
        },
        {
          title: "Valor",
          field: "value",
          sorting: false,
          render: (rowData) => {
            const v = Number(rowData.value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if (rowData.level > 0) {
              return v;
            }

            const o = getValueOperation(rowData);
            return `(${o})   ${v}`;
          },
        },
      ]}
      options={{
        rowStyle: getRowStyle,
      }}
      parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
    />
  );
};

const parseData = (data, tableData, level, parentId, parentPrefix) => {
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    const id = tableData.length;
    const prefix = parentPrefix
      ? `${parentPrefix}.${index + 1}`
      : (index + 1).toString();
    const newTableData = {
      id,
      parentId,
      level,
      name: element.name,
      value: element.value,
      index,
      prefix,
    };

    tableData.push(newTableData);

    if (Array.isArray(element.data)) {
      parseData(element.data, tableData, level + 1, id, prefix);
    }
  }
};

const ReportDRE = () => {
  useAuthenticated();
  const notify = useNotify();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  async function retrieveReportData({ lojaId, startDate, endDate }) {
    try {
      setLoading(true);
      const retrievedReportData = await UXDelay(
        fetchJSON("relatorio/contas/dre", null, {
          lojaId,
          startDate,
          endDate,
        })
      );
      const tableData = [];
      parseData(retrievedReportData, tableData, 0);
      setData(tableData);
    } catch (error) {
      console.error(error);
      notify("Erro ao buscar os dados.", "warning");
    } finally {
      setLoading(false);
    }
  }

  const dataEmpty = isEmpty(data);

  return (
    <Card className={classes.root}>
      <Title title="DRE – Demonstrativo de Resultados do Exercício" />
      <CardContent>
        <Search
          fields={["dateRange"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && <TableDRE data={data} />}
      </CardContent>
    </Card>
  );
};

export default ReportDRE;

export const reportDRE = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "relatorioDRE",
          to: "/relatorio/DRE",
          primaryText: "DRE",
        }
      : null,
};
