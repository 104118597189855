import React from "react";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import FichaTecnicaCreate from "./FichaTecnicaCreate";
import FichaTecnicaEdit from "./FichaTecnicaEdit";
import FichaTecnicaList from "./FichaTecnicaList";
import FichaTecnicaShow from "./FichaTecnicaShow";

const fichaTecnica = {
  getResource: (p, a) => {
    return {
      name: "ficha_tecnicas",
      list: p.cardapio_ver ? FichaTecnicaList : null,
      show: p.cardapio_ver && !p.cardapio_editar ? FichaTecnicaShow : null,
      create: p.cardapio_criar ? FichaTecnicaCreate : null,
      edit: p.cardapio_editar ? FichaTecnicaEdit : null,
    };
  },
  getMenu: (p, a) =>
    p.cardapio_ver
      ? {
          key: "ficha_tecnicas",
          to: "/ficha_tecnicas",
          primaryText: "Ficha Técnica",
          leftIcon: <AccountTreeIcon />,
        }
      : null,
};

export default fichaTecnica;
export const IconFichaTecninca = AccountTreeIcon;
