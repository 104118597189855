import React, { useCallback } from "react";
import { useNotify, Title } from "react-admin";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  List,
  CardActions,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Delete from "@material-ui/icons/Delete";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { fetchJSON } from "../dataProvider";
import LoadingButton from "../common/LoadingButton";
import UXDelay from "../dataProvider/uxdelay";
import iFoodLogo from "../static/media/ifood-logo.png";
import { withUser } from "../common/util/hocs";

const styles = {
  cover: {
    height: "100%",
    backgroundColor: "#ec2130",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  image: {
    maxHeight: "150px",
    padding: 100,
  },
  deleteButton: {
    color: "#f44336",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: fade("#f44336", 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
};

const useStyles = makeStyles((theme) => styles);

export function IFoodLogo() {
  const classes = useStyles();

  return (
    <div className={classes.cover}>
      <img src={iFoodLogo} className={classes.image} alt="iFood" />
    </div>
  );
}

function IFood({ loja }) {
  const classes = useStyles();
  const notify = useNotify();

  const [oauth, setOAuth] = React.useState(null);
  const [userCode, setUserCode] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [erro, setErro] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [codigoAutorizacao, setCodigoAutorizacao] = React.useState("");
  const [merchants, setMerchants] = React.useState([]);

  const fetchIFoodOauth = useCallback((lojaId) => {
    return fetchJSON(`IFood_oauth`, null, {
      filter: { where: { lojaId } },
    }).then((list) => (list && list.length > 0 ? list[0] : null));
  }, []);

  const parseResp = (oauth) => {
    setOAuth(oauth);
    if (oauth) {
      if (oauth.userCodeJson) {
        setUserCode(JSON.parse(oauth.userCodeJson));
      }

      if (oauth.tokenJson) {
        setToken(JSON.parse(oauth.tokenJson));
      }
    } else {
      setUserCode(null);
      setToken(null);
    }
  };

  React.useEffect(() => {
    UXDelay(fetchIFoodOauth(loja.id))
      .then((json) => parseResp(json))
      .catch((err) => {
        setErro(err);
        notify("Erro ao recuperar configurações da iFood", "warning");
      })
      .finally(() => setLoading(false));
  }, [loja, notify, fetchIFoodOauth]);

  React.useEffect(() => {
    if (userCode && token) {
      fetchJSON(`IFood/merchants/status`, null, { lojaId: loja.id })
        .then((list) => setMerchants(list))
        .catch((err) => {
          setErro(err);
          notify("Erro ao recuperar lojas do iFood", "warning");
        });
    }
  }, [loja, userCode, token, notify]);

  const handleInstalar = () => {
    setLoading(true);
    UXDelay(
      fetchJSON(`IFood/oauth/userCode`, {
        method: "POST",
        body: JSON.stringify({
          lojaId: loja.id,
        }),
      })
    )
      .then((json) => {
        parseResp(json);
        notify("iFood instalado com sucesso!");
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao instalar o iFood", "warning");
      })
      .finally(() => setLoading(false));
  };

  const handleSalvarAutorizacao = () => {
    setLoading(true);
    UXDelay(
      fetchJSON(`IFood/oauth/token`, {
        method: "POST",
        body: JSON.stringify({
          lojaId: loja.id,
          authorizationCode: codigoAutorizacao,
        }),
      }).then(() => fetchIFoodOauth(loja.id))
    )
      .then((json) => {
        parseResp(json);
        notify("Autorização realizada com sucesso!");
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao salvar código de autorização do iFood", "warning");
      })
      .finally(() => setLoading(false));
  };

  const handleDesinstalar = () => {
    setLoading(true);
    UXDelay(
      fetchJSON(`IFood_oauth/del`, {
        method: "DELETE",
        body: JSON.stringify({
          id: oauth.id,
          lojaId: loja.id,
        }),
      })
    )
      .then((json) => {
        parseResp(null);
        notify("iFood desinstalado com sucesso!");
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao desinstalar iFood", "warning");
      })
      .finally(() => setLoading(false));
  };

  const handleChangeCodigo = (event) => {
    setCodigoAutorizacao(event.target.value);
  };

  return (
    <Card sx={{ display: "flex", p: 3 }}>
      <Title title="Integração do iFood" />
      <Box sx={{ width: 1 / 4 }}>
        <IFoodLogo />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography variant="h5" color="textPrimary" sx={{ mb: 2 }}>
            iFood
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Receba no Caixa os pedidos realizados através do iFood. Sem
            retrabalho, o sistema já envia para a produção e emite a nota
            fiscal.
          </Typography>
          {erro && (
            <Typography variant="body2" color="error" sx={{ my: 2 }}>
              {erro.message}
            </Typography>
          )}
          {userCode && !token && (
            <Box my={5}>
              <Typography variant="h6" color="textPrimary" sx={{ mb: 2 }}>
                Autorização Pendente
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ mb: 2 }}
              >
                <Grid item>
                  <TextField
                    label="Código de aplicativo"
                    variant="filled"
                    value={userCode.userCode}
                  />
                </Grid>
                <Grid item>
                  <a
                    href={userCode.verificationUrlComplete}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Autorizar no portal do iFood
                  </a>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ mb: 2 }}
              >
                <Grid item>
                  <TextField
                    label="Código de autorização"
                    variant="filled"
                    onChange={handleChangeCodigo}
                  />
                </Grid>
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleSalvarAutorizacao}
                    text="Salvar autorização"
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          {userCode && token && merchants && merchants.length > 0 && (
            <Box sx={{ marginY: 3 }}>
              <Typography variant="h6" color="textPrimary">
                Lojas autorizadas:
              </Typography>
              <List>
                {merchants.map((merchant, i) => (
                  <ListItem sx={{ marginY: 0, paddingY: 0 }}>
                    <ListItemAvatar>
                      <Avatar>{i + 1}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={merchant.name}
                      secondary={`${merchant.status[0].operation}: ${merchant.status[0].message.subtitle}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </CardContent>
        <CardActions
          sx={{ px: 2, py: 0, justifyContent: "space-between", width: "100%" }}
        >
          {!oauth && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleInstalar}
              text="Instalar"
              loading={loading}
            />
          )}
          {oauth && (
            <LoadingButton
              variant="outlined"
              color="error"
              style={styles.deleteButton}
              onClick={handleDesinstalar}
              icon={<Delete />}
              text="Desinstalar"
              loading={loading}
            />
          )}
        </CardActions>
      </Box>
    </Card>
  );
}

export default withUser(IFood);
