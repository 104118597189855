import React from "react";
import { Resource } from "react-admin";
import equipamento from "../resources/equipamento";
import grupo from "../resources/grupo";
import imposto from "../resources/imposto";
import impressora from "../resources/impressora";
import pergunta from "../resources/pergunta";
import resposta from "../resources/resposta";
import produto from "../resources/produto";
import produto_ponte from "../resources/produto_ponte";
import produto_combo from "../resources/produto_combo";
import unidade from "../resources/unidade";
import comanda from "../resources/comanda";
import caixa from "../resources/caixa";
import moeda from "../resources/moeda";
import moeda_app from "../resources/moeda_app";
import moeda_tipo from "../resources/moeda_tipo";
import moeda_adquirente from "../resources/moeda_adquirente";
import ncm from "../resources/ncm";
import nps from "../resources/nps";
import cest from "../resources/cest";
import loja, { lojaAdmin } from "../resources/loja";
import loja_entrega from "../resources/loja_entrega";
import loja_horario from "../resources/loja_horario";
import loja_moeda_app from "../resources/loja_moeda_app";
import promocao_app from "../resources/promocao_app";
import usuario from "../resources/usuario";
import log from "../resources/log";
import appPedido from "../resources/app pedido";
import parceiro from "../resources/parceiro";
import usuario_loja from "../resources/usuario_loja";
import usuario_log from "../resources/usuario_log";
import pedidos from "../resources/pedidos";
import nfe_eventos from "../resources/nfe_tomada";
import nfe_emissao from "../resources/nfe_emissao";
import inventario from "../resources/inventario";
import ficha_tecnica from "../resources/produto_ficha_tecnica";
import certificado_digital from "../resources/certificado_digital";
import conta from "../resources/conta";
import conta_classificacao from "../resources/conta_classificacao";
import conta_centro_custo from "../resources/conta_centro_custo";
import ifood_oauth from "../resources/ifood_oauth";
import mercado_pago_preference from "../resources/mercado_pago_preference";
import fidelidade from "../resources/fidelidade";
import contador_arquivo from "../resources/contador_arquivo";
import movimento from "../resources/movimento";
import nfce from "../resources/nfce";
import kds from "../resources/kds";
import connect_stone from "../resources/connect_stone";
import transaction_pos from "../resources/transaction_pos";
import whatsapp from "../resources/whatsapp";

const resourcesMenu = [
  appPedido,
  log,
  usuario,
  certificado_digital,
  ifood_oauth,
  whatsapp,

  loja,
  lojaAdmin,

  loja_entrega,
  loja_horario,
  loja_moeda_app,
  promocao_app,

  moeda_adquirente,
  moeda_tipo,
  moeda_app,
  ncm,
  cest,
  nps,

  usuario_log,
  equipamento,
  grupo,
  imposto,
  impressora,
  pergunta,
  resposta,
  produto,
  produto_ponte,
  produto_combo,
  unidade,
  caixa,
  moeda,

  parceiro,
  comanda,
  usuario_loja,

  pedidos,
  conta,
  conta_classificacao,
  conta_centro_custo,
  nfe_eventos,
  nfe_emissao,
  inventario,
  ficha_tecnica,
  mercado_pago_preference,
  fidelidade,
  contador_arquivo,
  movimento,
  nfce,
  connect_stone,
  transaction_pos,
  kds,
];

const resourcesString = [
  "user_lojas",
  "grupo_adds",
  "equipamento_eventos",
  "movimentos",
  "segmentos",
  "autoatendimento_config",
  "impressao_configs",
  "etiqueta_configs",
  "Pagarme_auth",

  "AppUsers",
  "AppEventos",
  "AppPedido_loja",
  "Loja_tabela_precos",
];

const getResources = ({ admin_user_active, permissao, admin_user }) => {
  const resources = admin_user_active
    ? [...resourcesMenu, ...resourcesString]
    : resourcesString;

  return resources.map((r, i) =>
    typeof r === "string" ? (
      <Resource key={r} name={r} />
    ) : (
      <Resource key={i} {...r.getResource(permissao, admin_user)} />
    )
  );
};

export default getResources;
