import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const nfeTipo = [
  { value: "NFe Tomada", text: "NFe Tomada" },
  { value: "NFe Emitida", text: "NFe Emitida" },
];

function NFeTomadaEmitida({ value, onChange, id }) {
  const handleChange = (event) => onChange(id, event.target.value);

  return (
    <FormControl variant="standard" sx={{ minWidth: 100 }}>
      <InputLabel>NFe Tipo</InputLabel>
      <Select value={value} onChange={handleChange}>
        {nfeTipo.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default NFeTomadaEmitida;
