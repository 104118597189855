export const isHighLevel = (permissao, admin_user) =>
  permissao.ADMIN || permissao.PROPRIETARIO;

//
//    CARDAPIO DIGITAL
//
export const cardapioDigitalEnable = (permissao, admin_user) =>
  permissao.cardapio_digital_ver && admin_user.cardapio_digital;

export const reportCardapioDigitalEnable = (permissao, admin_user) =>
  permissao.cardapio_digital_relatorios && admin_user.cardapio_digital;

export const publicarMenu = (permissao, admin_user) =>
  permissao.cardapio_publicar;

//
//    NUVEM
//
export const reportPDVEnable = (permissao, admin_user) =>
  permissao.pdv_relatorios && admin_user.nuvem;

export const estoqueEnable = (permissao, admin_user) =>
  permissao.estoque_ver && admin_user.nuvem;

export const reportEstoqueEnable = (permissao, admin_user) =>
  permissao.estoque_relatorios && admin_user.nuvem;

export const contasEnable = (permissao, admin_user) =>
  permissao.contas_ver && admin_user.nuvem;

export const reportContasEnable = (permissao, admin_user) =>
  permissao.contas_relatorios && admin_user.nuvem;

export const caixasEnable = (permissao, admin_user) =>
  permissao.caixa_conferir && admin_user.nuvem;

//
//    OUTROS
//
export const comandasAbertas = (permissao, admin_user) =>
  (permissao.cardapio_digital_ver && admin_user.cardapio_digital) ||
  admin_user.nuvem;
