import React from "react";
import {
  Datagrid,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from "react-admin";
import ListActions from "../../common/ListActions";
import CurrencyField from "../../common/form/CurrencyField";
import PermissionEditButton from "../../common/form/PermissionEditButton";
import { withUser } from "../../common/util/hocs";
import AnaliseCustoButton from "./AnaliseCustoButton";

const fichaTecnicaActionsList = [<AnaliseCustoButton />];

const FichaTecnica = ({ permissao, ...rest }) => (
  <List
    {...rest}
    actions={
      <ListActions
        actionsList={permissao.cardapio_editar ? fichaTecnicaActionsList : null}
      />
    }
    bulkActionButtons={false}
    exporter={false}
  >
    <Datagrid>
      <NumberField source="id" label="#" />
      <ReferenceField source="produtoId" reference="produtos" label="Código">
        <TextField source="cod_prod" />
      </ReferenceField>
      <ReferenceField
        source="produtoId"
        reference="produtos"
        label="Descrição"
        link={false}
      >
        <TextField source="desc_prod" />
      </ReferenceField>
      <ReferenceField
        source="produtoId"
        reference="produtos"
        label="Custo"
        link={false}
      >
        <CurrencyField source="preco_cst_prod" />
      </ReferenceField>
      <PermissionEditButton canEdit="cardapio_editar" canShow="cardapio_ver" />
    </Datagrid>
  </List>
);

export default withUser(FichaTecnica);
