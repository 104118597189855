import React, { useEffect } from "react";
import { keyBy } from "lodash";
import { Dialog, DialogTitle, InputAdornment, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import {
  BooleanInput,
  Datagrid,
  Button as RAButton,
  SimpleForm,
  TextField,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  NumberInput,
  required,
  minValue,
  maxValue,
  FunctionField,
  useNotify,
  useRefresh,
} from "react-admin";
import MargemUpDown from "../nfe_tomada/MargemUpDown";
import CircularProgress from "../../common/CircularProgress";
import { withUser } from "../../common/util/hocs";
import DatagridEmpty from "../../common/form/Datagrid/DatagridEmpty";
import { validateDecimalPlaces } from "../../common/util/ra-validations";
import NumberInputRemoveArrowsCSS from "../../common/form/NumberInputRemoveArrowsCSS.json";
import { calculateProfitMargin } from "../../common/util/util";
import { formatCurrency } from "../../common/util/formatter";
import { useChangeCalcAnaliseCusto } from "./useChangeCalc";
import { parseDataAnaliseCusto } from "./calculos";
import { cleanCache, fetchJSON } from "../../dataProvider";

const useStyles = makeStyles({
  valorInput: {
    width: "9em",
    ...NumberInputRemoveArrowsCSS,
  },
  margemInput: {
    width: "5em",
    ...NumberInputRemoveArrowsCSS,
  },
});

const validateReq = [required()];
const validateVenda = [
  required(),
  minValue(0),
  maxValue(9999999999.99),
  validateDecimalPlaces(2),
];

const MudaPrecoInput = (props) => {
  return (
    <FormDataConsumer {...props}>
      {({ formData, record }) => {
        const { index } = record;
        return (
          <BooleanInput label="" source={`produtos[${index}].mudaPreco`} />
        );
      }}
    </FormDataConsumer>
  );
};

const MargemInput = (props) => {
  const classes = useStyles();
  const changeCalc = useChangeCalcAnaliseCusto();
  return (
    <FormDataConsumer {...props}>
      {({ formData, record }) => {
        const { index } = record;
        const dtgFormData = formData.produtos[index];
        if (!dtgFormData.mudaPreco) return null;
        return (
          <NumberInput
            label=""
            variant="standard"
            source={`produtos[${index}].margem`}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            onChange={changeCalc}
            validate={validateReq}
            className={classes.margemInput}
          />
        );
      }}
    </FormDataConsumer>
  );
};

const VendaInput = (props) => {
  const classes = useStyles();
  const changeCalc = useChangeCalcAnaliseCusto();
  return (
    <FormDataConsumer {...props}>
      {({ formData, record }) => {
        const { index } = record;
        const dtgFormData = formData.produtos[index];
        if (!dtgFormData.mudaPreco) return null;
        return (
          <NumberInput
            label=""
            variant="standard"
            source={`produtos[${index}].venda`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
            onChange={changeCalc}
            validate={validateVenda}
            className={classes.valorInput}
          />
        );
      }}
    </FormDataConsumer>
  );
};

const MargemUpDownField = (props) => {
  return (
    <FormDataConsumer {...props}>
      {({ formData, record }) => {
        const margem = calculateProfitMargin(
          record.preco_cst_prod,
          record.custo,
          0
        );
        return <MargemUpDown diff={margem} />;
      }}
    </FormDataConsumer>
  );
};

const CustoField = (props) => {
  return (
    <FormDataConsumer {...props}>
      {({ formData, record }) => {
        return (
          <Typography variant="body2" align="right">
            <strong>{formatCurrency(record.custo, 2, 4)}</strong>
          </Typography>
        );
      }}
    </FormDataConsumer>
  );
};

const DataGridProdutos = ({ record }) => {
  const data = keyBy(record.produtos, "id");
  const ids = record.produtos.map(({ id }) => id);

  return (
    <Datagrid
      loaded={true}
      data={data}
      ids={ids}
      currentSort={{ field: "id", order: "ASC" }}
      fullWidth
      empty={
        <DatagridEmpty message="Nenhuma ficha técnica teve alteração no preço de custo." />
      }
    >
      <TextField source="cod_prod" label="Código" />
      <TextField source="desc_prod" label="Descrição" />
      <FunctionField
        label="Custo Atual"
        render={(record) => (
          <strong>{formatCurrency(record.preco_cst_prod, 2, 4)}</strong>
        )}
      />
      <FunctionField
        label="Markup Atual"
        render={(record) => `${record.margem_atual}%`}
      />
      <FunctionField
        label="Venda Atual"
        render={(record) => formatCurrency(record.preco_vnd_prod)}
      />
      <MudaPrecoInput label="Muda Preço de Venda?" />
      <MargemUpDownField label={<ShuffleIcon />} />
      <CustoField label="Custo Novo" />
      <MargemInput label="Markup Novo" />
      <VendaInput label="Venda Nova" />
    </Datagrid>
  );
};

const AnaliseCustoButton = ({ permissao, loja }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [record, setRecord] = React.useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const data = await fetchJSON("ficha_tecnicas/analiseCusto", null, {
          lojaId: loja.id,
        });
        setRecord(parseDataAnaliseCusto(data));
      } catch (exception) {
        console.error(exception);
        console.log(JSON.stringify(exception));
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [loja.id]);

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const handleSave = async (formData) => {
    try {
      cleanCache("produtos");
      cleanCache("ficha_tecnicas");
      await fetchJSON("ficha_tecnicas/analiseCustoAtualizaPreco", {
        method: "POST",
        body: {
          lojaId: formData.lojaId,
          produtos: formData.produtos,
        },
      });
      refresh();
      setOpen(false);
    } catch (exception) {
      notify(`Ocorreu um erro ao atualizar os preços dos produtos.`);
      console.error(exception);
      console.log(JSON.stringify(exception));
    }
  };

  return (
    <>
      <RAButton label="Análise de Custo" onClick={handleOpen} />
      <Dialog fullWidth open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle>Análise de Custo</DialogTitle>
        {loading && <CircularProgress />}
        {!loading && record && (
          <SimpleForm
            record={record}
            submitOnEnter={false}
            toolbar={
              <Toolbar>
                <SaveButton onSave={handleSave} />
              </Toolbar>
            }
          >
            <DataGridProdutos record={record} />
          </SimpleForm>
        )}
      </Dialog>
    </>
  );
};

export default withUser(AnaliseCustoButton);
