import React, { useState } from "react";
import SendIcon from "@material-ui/icons/Send";
import LoadingButton from "../../common/LoadingButton";
import { cleanCache, fetchJSON } from "../../dataProvider";
import { HttpError, useNotify, useRefresh, useDataProvider } from "react-admin";

const NFCeEmitirButton = ({ movimento, record: nfce }) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  if (nfce && nfce.xmlUrl) {
    return null;
  }

  if (nfce && nfce.xmlCancelamentoUrl) {
    return null;
  }

  const emitirNFCe = async () => {
    try {
      setLoading(true);

      if (nfce) {
        const response = await dataProvider.getOne("Movimentos", {
          id: nfce.movimentoId,
        });
        movimento = response.data;
      }

      await fetchJSON("Sefaz/emiteNFCe", {
        method: "POST",
        body: {
          lojaId: movimento.lojaId,
          equipamentoId: movimento.equipamentoId,
          movimentoId: movimento.id,
        },
      });

      cleanCache("NFCes");
      refresh();
    } catch (err) {
      console.log(JSON.stringify(err));

      if (err instanceof HttpError) {
        if (err.status === 400) {
          notify(err.message);
          return;
        }
      }

      notify("Ocorreu um erro ao emitir a NFCe");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      text="Emitir"
      loading={loading}
      icon={<SendIcon />}
      onClick={emitirNFCe}
    />
  );
};

export default NFCeEmitirButton;
