/**
 * Rotas customizadas, aquelas que não são consumidas por Resource do react-admin
 *
 */

import React from "react";
import { Route } from "react-router-dom";

import Integracoes from "../configuracao/Integracoes";
import MatrizFilial from "../configuracao/MatrizFilial";
import MercadoPago from "../configuracao/MercadoPago";
import IFood from "../configuracao/IFood";
import CertificadoDigital from "../configuracao/CertificadoDigital";
import AlterarSenha from "../configuracao/AlterarSenha";
import PublicarMenu from "../configuracao/PublicarMenu";
import AutoatendimentoConfig from "../configuracao/AutoatendimentoConfig";
import ImpressaoConfig from "../configuracao/ImpressaoConfig";
import EtiquetaConfig from "../configuracao/EtiquetaConfig";
import ImportarProdutos from "../common/importCSV/ImportarProdutos";
import ImportarNCM from "../common/importCSV/ImportarNCM";
import ImportarPerguntas from "../common/importCSV/ImportarPerguntas";
import ImportarAreasEntrega from "../common/importCSV/ImportarAreasEntrega";

import ReportDRE from "../reports/ReportDRE";
import ReportNFCe from "../reports/ReportNFCe";
import ReportNFe from "../reports/ReportNFe";
import ReportItensDeletados from "../reports/ReportItensDeletados";
import ReportSaldoCorrentistas from "../reports/ReportSaldoCorrentistas";
import ReportCurvaABC from "../reports/ReportCurvaABC";
import ReportCurvaABCTipoMov from "../reports/ReportCurvaABCTipoMov";
import ReportEngenhariaCardapio from "../reports/ReportEngenhariaCardapio";
import ReportTicketMedio from "../reports/ReportTicketMedio";
import ReportConciliacaoBancaria from "../reports/ReportConciliacaoBancaria";
import ReportFaturamentoMoeda from "../reports/ReportFaturamentoMoeda";
import ReportProducts from "../reports/Report10Products";
import ReportSalesByChannel from "../reports/ReportSalesByChannel";
import ReportSalesByServiceType from "../reports/ReportSalesByServiceType";
import ReportResult from "../reports/ReportResult";
import DefinePasswordForm from "../common/form/DefinePasswordForm";
import RecoveryPasswordForm from "../common/form/RecoveryPasswordForm";

import CDPReportCurvaABC from "../reports/ReportCurvaABC/ReportCurvaABC";
import ReportDailyInvoicings from "../reports/ReportDailyInvoicings/ReportDailyInvoicings";
import ReportWeeklyPerformance from "../reports/ReportWeeklyPerformance/ReportWeeklyPerformance";
import RegisterWizard from "../layout/RegisterWizard";
import ProdutoListEditable from "../resources/produto/ProdutoListEditable";
import PedidoDetails from "../resources/pedidos/PedidoDetails";
import EstoqueList from "../resources/estoque/EstoqueList";
import PdvLocalList from "../resources/pdv_local/PdvLocalList";
import EstoqueMovimentacao from "../resources/estoque/EstoqueMovimentacao";
import ReportBillsByClassification from "../reports/ReportBills/ReportBillsByClassification";
import ReportBillsCashier from "../reports/ReportBills/ReportBillsCashier";
import ReportBillsByPartners from "../reports/ReportBills/ReportBillsByPartner";
import ReportPartnerComission from "../reports/ReportPartnerComission";
import {
  comandasAbertas,
  publicarMenu,
  reportCardapioDigitalEnable,
  reportContasEnable,
  reportEstoqueEnable,
  reportPDVEnable,
} from "../permissionsHelper";
import PickNGo from "../configuracao/PickNGo";
import BeeDelivery from "../configuracao/BeeDelivery";
import Pagarme from "../configuracao/Pagarme";
import PagBank from "../configuracao/PagBank";
import WhatsApp from "../configuracao/WhatsApp";
import MatrixDistance from "../configuracao/MatrixDistance";

const usuariosRoutes = [
  <Route exact path="/alterarSenha" component={AlterarSenha} />,
  <Route noLayout exact path="/confirmacao" component={DefinePasswordForm} />,
  <Route
    noLayout
    exact
    path="/recuperarSenha"
    component={RecoveryPasswordForm}
  />,
  <Route noLayout exact path="/cadastro" component={RegisterWizard} />,
  <Route noLayout exact path="/pedidoDetalhes" component={PedidoDetails} />,
];

export default function getRoutes(config) {
  if (!config.admin_user_active) {
    return usuariosRoutes;
  }

  const routes = [...usuariosRoutes];

  const { permissao, admin_user } = config;

  if (permissao.ADMIN || permissao.PROPRIETARIO) {
    routes.push(
      <Route exact path="/importarPerguntas" component={ImportarPerguntas} />,
      <Route exact path="/importarProdutos" component={ImportarProdutos} />,
      <Route
        exact
        path="/importarAreasEntrega"
        component={ImportarAreasEntrega}
      />,
      <Route exact path="/ImportarNCM" component={ImportarNCM} />,
      <Route exact path="/integracoes" component={Integracoes} />,
      <Route
        exact
        path="/integracoes/matriz-filial-config"
        component={MatrizFilial}
      />,
      <Route
        exact
        path="/integracoes/stone-pagarme-config"
        component={Pagarme}
      />,
      <Route exact path="/integracoes/pagbank-config" component={PagBank} />,
      <Route exact path="/integracoes/mercadoPago" component={MercadoPago} />,
      <Route exact path="/integracoes/ifood" component={IFood} />,
      <Route exact path="/integracoes/pickNGo" component={PickNGo} />,
      <Route exact path="/integracoes/beeDelivery" component={BeeDelivery} />,
      <Route
        exact
        path="/integracoes/certificado-digital-config"
        component={CertificadoDigital}
      />,
      <Route
        exact
        path="/integracoes/autoatendimento-config"
        component={AutoatendimentoConfig}
      />,
      <Route
        exact
        path="/integracoes/impressao-config"
        component={ImpressaoConfig}
      />,
      <Route
        exact
        path="/integracoes/etiqueta-config"
        component={EtiquetaConfig}
      />,
      <Route exact path="/integracoes/whatsapp-config" component={WhatsApp} />,
      <Route exact path="/integracoes/maps" component={MatrixDistance} />
    );
  }

  if (comandasAbertas(permissao, admin_user)) {
    routes.push(
      <Route exact path="/pdv-vendas-abertas" component={PdvLocalList} />
    );
  }

  if (permissao.cardapio_editar) {
    routes.push(
      <Route exact path="/produtosAlteracao" component={ProdutoListEditable} />
    );
  }

  if (publicarMenu(permissao, admin_user)) {
    routes.push(<Route exact path="/publicarMenu" component={PublicarMenu} />);
  }

  if (reportCardapioDigitalEnable(permissao, admin_user)) {
    routes.push(
      <Route
        exact
        path="/relatorio/daily_invoicings"
        component={ReportDailyInvoicings}
      />,
      <Route
        exact
        path="/relatorio/weekly_performance"
        component={ReportWeeklyPerformance}
      />,
      <Route
        exact
        path="/relatorio/cdp_curva_abc"
        component={CDPReportCurvaABC}
      />
    );
  }

  if (reportPDVEnable(permissao, admin_user)) {
    routes.push(
      <Route exact path="/relatorio/dre" component={ReportDRE} />,
      <Route exact path="/relatorio/nfce" component={ReportNFCe} />,
      <Route exact path="/relatorio/nfe" component={ReportNFe} />,
      <Route
        exact
        path="/relatorio/itens-deletados"
        component={ReportItensDeletados}
      />,
      <Route
        exact
        path="/relatorio/saldo-correntistas"
        component={ReportSaldoCorrentistas}
      />,
      <Route
        exact
        path="/relatorio/engenharia-cardapio"
        component={ReportEngenhariaCardapio}
      />,
      <Route exact path="/relatorio/curva-abc" component={ReportCurvaABC} />,
      <Route
        exact
        path="/relatorio/curva-abc-tipo-mov"
        component={ReportCurvaABCTipoMov}
      />,
      <Route
        exact
        path="/relatorio/ticket-medio"
        component={ReportTicketMedio}
      />,
      <Route
        exact
        path="/relatorio/conciliacao-bancaria"
        component={ReportConciliacaoBancaria}
      />,
      <Route
        exact
        path="/relatorio/faturamento-moeda"
        component={ReportFaturamentoMoeda}
      />,
      <Route
        exact
        path="/relatorio/comissao-parceiro"
        component={ReportPartnerComission}
      />,
      <Route
        exact
        path="/relatorio/produtos-mais-vendidos"
        component={ReportProducts}
      />,
      <Route
        exact
        path="/relatorio/comparar-canal-vendas"
        component={ReportSalesByChannel}
      />,
      <Route
        exact
        path="/relatorio/comparar-tipos-atendimento"
        component={ReportSalesByServiceType}
      />,
      <Route exact path="/relatorio/resultado" component={ReportResult} />
    );
  }

  if (reportEstoqueEnable(permissao, admin_user)) {
    routes.push(
      <Route exact path="/estoque" component={EstoqueList} />,
      <Route
        exact
        path="/estoque/:produtoId/movimentacao"
        component={EstoqueMovimentacao}
      />
    );
  }

  if (reportContasEnable(permissao, admin_user)) {
    routes.push(
      <Route
        exact
        path="/Conta/analise-contas-pagas"
        component={ReportBillsByClassification}
      />,
      <Route
        exact
        path="/Conta/pagas-no-caixa"
        component={ReportBillsCashier}
      />,
      <Route
        exact
        path="/Conta/contas-por-parceiro"
        component={ReportBillsByPartners}
      />
    );
  }

  return routes;
}
