import React, { cloneElement, useState } from "react";
import { Box, Stack } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import { withUser } from "../../common/util/hocs";
import DateRange from "./DateRange";
import BillType from "./BillType";
import LoadingButton from "../../common/LoadingButton";
import moment from "moment";
import BankStatement from "./BankStatement";
import Group from "./Group";
import Unit from "./Unit";
import OrderABC from "./OrderABC";
import IntervalHour from "./IntervalHour";
import SalesChannels from "./SalesChannels";
import ServiceTypes from "./ServiceTypes";
import Stores from "./Stores";
import Partners from "./Partners";
import PartnerType, { COMISSION_TYPES_OPTIONS } from "./ComissionType";
import VisualizationMode from "./VisualizationMode";
import TimeRange from "./TimeRange";
import MovTypes from "./MovTypes";
import Error from "../../common/Error";
import Equipamentos from "./Equipamentos";
import CdpModes from "./CdpModes";
import NFeTomadaEmitida from "./NFeTomadaEmitida";

const filters = {
  dateRange: {
    component: <DateRange />,
    initialValue: {
      startDate: moment(),
      endDate: moment(),
    },
    parse: (value, allValues) => {
      let { startDate, endDate } = value;
      startDate = startDate.clone();
      endDate = endDate.clone();

      if (allValues.timeRange) {
        const startTime = allValues.timeRange.startTime.split(`:`);
        startDate.set({ h: startTime[0], m: startTime[1], s: 0, ms: 0 });

        const endTime = allValues.timeRange.endTime.split(`:`);
        endDate.set({ h: endTime[0], m: endTime[1], s: 59, ms: 999 });
      } else {
        startDate = startDate.startOf("day");
        endDate = endDate.endOf("day");
      }

      return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        startDateNoTime: startDate.format("YYYY-MM-DD"),
        endDateNoTime: endDate.format("YYYY-MM-DD"),
        days: endDate.diff(startDate, "days") + 1,
      };
    },
  },
  timeRange: {
    component: <TimeRange />,
    initialValue: {
      startTime: "00:00",
      endTime: "23:59",
    },
  },
  stores: {
    component: <Stores />,
    initialValue: [],
    parse: (value, allValues) => ({
      stores: [allValues.lojaId, ...value],
    }),
  },
  billType: {
    component: <BillType />,
    initialValue: "PAGAR",
  },
  bankStatement: {
    component: <BankStatement />,
    initialValue: "extrato",
  },
  movTypes: {
    component: <MovTypes />,
    initialValue: [4, 5],
  },
  groups: {
    component: <Group />,
    initialValue: [],
  },
  partners: {
    component: <Partners />,
    initialValue: [],
  },
  equipamentos: {
    component: <Equipamentos />,
    initialValue: [],
  },
  partnerType: {
    component: <PartnerType />,
    initialValue: COMISSION_TYPES_OPTIONS[1],
  },
  cdpModes: {
    component: <CdpModes />,
    initialValue: [],
  },
  units: {
    component: <Unit />,
    initialValue: [],
  },
  salesChannels: {
    component: <SalesChannels />,
    initialValue: [],
  },
  serviceTypes: {
    component: <ServiceTypes />,
    initialValue: [],
  },
  orderABC: {
    component: <OrderABC />,
    initialValue: "valor_final",
  },
  intervalHour: {
    component: <IntervalHour />,
    initialValue: 8,
  },
  nfe: {
    component: <NFeTomadaEmitida />,
    initialValue: "NFe Tomada",
  },
};

const getInitialValues = (fields, loja) => {
  return fields.reduce(
    (hash, field) => ({
      ...hash,
      [field]: filters[field].initialValue,
    }),
    { lojaId: loja.id }
  );
};

function Search({
  onSearch,
  onVisualizationModeChange,
  loja,
  loading,
  exception,
  fields,
  dataEmpty,
}) {
  const [hasClicked, setClicked] = useState(false);
  const [values, setValues] = useState(getInitialValues(fields, loja));

  const handleChange = (fieldId, value) => {
    setValues({ ...values, [fieldId]: value });
  };

  const handleClick = () => {
    const searchValues = fields.reduce(
      (hash, field) => {
        const filter = filters[field];
        const parse = filter.parse;
        const value = values[field];
        const parsedValue = parse ? parse(value, values) : value;
        return typeof parsedValue === "object" && !Array.isArray(parsedValue)
          ? {
              ...hash,
              ...parsedValue,
            }
          : {
              ...hash,
              [field]: values[field],
            };
      },
      { lojaId: loja.id }
    );

    onSearch(searchValues);
    setClicked(true);
  };

  return (
    <Stack spacing={5} sx={{ mb: 5 }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        {fields.map((field) => {
          const filter = filters[field];
          return cloneElement(filter.component, {
            key: field,
            id: field,
            value: values[field],
            onChange: handleChange,
          });
        })}
        <LoadingButton
          text="Pesquisar"
          variant="contained"
          size="large"
          color="primary"
          onClick={handleClick}
          icon={<SearchIcon />}
          loading={loading}
        />
        {onVisualizationModeChange instanceof Function && (
          <VisualizationMode onChange={onVisualizationModeChange} />
        )}
      </Stack>
      {exception && <Error error={exception} goBackVisible={false} />}
      {!exception && !loading && dataEmpty && hasClicked && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginY={3}
        >
          <p>Nenhum registro localizado.</p>
        </Box>
      )}
    </Stack>
  );
}

export default withUser(Search);
