import { AutocompleteInput, required } from "react-admin";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import Highlighted from "../../common/Highlighted";

const filter = (searchText) => {
  if (searchText.match(/^\d+$/)) {
    return { id: { like: `${searchText}%` } };
  } else {
    return { q: searchText };
  }
};

const matchSuggestion = (filter, choice) => true;

function CestItem({ record, filterValue }) {
  record = record && record.id ? record : { id: "Nenhum", descricao: "-" };
  const { id, descricao } = record;
  return (
    <div>
      <Highlighted text={id.toString()} highlight={filterValue} />
      <br />
      <small>
        <Highlighted text={descricao} highlight={filterValue} />
      </small>
    </div>
  );
}

const validateParceiro = [required()];

function CestLazyReferenceInput({ nameSource, required, ...rest }) {
  return (
    <LazyReferenceInput
      reference="cests"
      nameSource={nameSource || "cest"}
      filterToQuery={filter}
      minLength={3}
      limit={25}
      {...rest}
    >
      <AutocompleteInput
        validate={required ? validateParceiro : null}
        matchSuggestion={matchSuggestion}
        optionText={<CestItem />}
        inputText={(record) =>
          record ? `${record.id} - ${record.descricao}` : ""
        }
      />
    </LazyReferenceInput>
  );
}

export default CestLazyReferenceInput;
