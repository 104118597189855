import { Avatar, Grid, Typography } from "@mui/material";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

const MargemUpDown = ({ diff, ...rest }) => {
  if (diff > 0) {
    return (
      <Avatar sx={{ bgcolor: green[500], width: 56, height: 56 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          {...rest}
        >
          <Grid item>
            <TrendingDownIcon />
          </Grid>
          <Grid item>
            <Typography variant="body2">
              <strong>{`${diff}%`}</strong>
            </Typography>
          </Grid>
        </Grid>
      </Avatar>
    );
  }

  if (diff < 0) {
    return (
      <Avatar sx={{ bgcolor: red[500], width: 56, height: 56 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          {...rest}
        >
          <Grid item>
            <TrendingUpIcon />
          </Grid>
          <Grid item>
            <Typography variant="body2">
              <strong>{`${-diff}%`}</strong>
            </Typography>
          </Grid>
        </Grid>
      </Avatar>
    );
  }

  return <Avatar sx={{ bgcolor: grey[500], width: 56, height: 56 }}>=</Avatar>;
};

export default MargemUpDown;
