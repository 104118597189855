import React, { useState, useRef } from "react";
import { useAuthenticated } from "react-admin";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  processCsvFile,
  normalizeColumnsValues,
  checkRequiredFields,
} from "./csv-extractor";
import { Button } from "@material-ui/core";
import LoadingButton from "../LoadingButton";
import Table from "../table/Table";
import { fetchJSON, save } from "../../dataProvider";
import { withUser } from "../util/hocs";

function ImportarNCM(props) {
  useAuthenticated();

  const tableRef = useRef();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtrado, setFiltrado] = useState(false);

  //codigo;ex;tipo;descricao;nacionalfederal;importadosfederal;estadual;municipal;vigenciainicio;vigenciafim;chave;versao;fonte
  //00000000;;0;"PRODUTO NAO ESPECIFICADO NA LISTA DE NCM";4.20;6.20;0.00;0.00;20/07/2022;31/08/2022;3E8D48;22.2.C;IBPT/empresometro.com.br

  const columnsAll = [
    { title: "codigo", field: "codigo", type: "string" },
    { title: "ex", field: "ex", type: "string" },
    { title: "tipo", field: "tipo", type: "string" },
    { title: "descricao", field: "descricao", type: "string" },
    { title: "n.federal", field: "nacionalfederal", type: "numeric" },
    { title: "i.federal", field: "importadosfederal", type: "numeric" },
    { title: "estadual", field: "estadual", type: "numeric" },
    { title: "municipal", field: "municipal", type: "numeric" },
    { title: "inicio", field: "vigenciainicio", type: "string" },
    { title: "fim", field: "vigenciafim", type: "string" },
    { title: "chave", field: "chave", type: "string" },
    { title: "versao", field: "versao", type: "string" },
    { title: "fonte", field: "fonte", type: "string" },
  ];

  const onFileAdded = async (e) => {
    const file = e.target.files && e.target.files[0];
    try {
      const values = await processCsvFile(file);

      if (values.length > 0) {
        const data = normalizeColumnsValues(values, columnsAll);
        setFiltrado(false);
        setColumns(data.columns);
        setRows(data.values);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const onFilter = async () => {
    try {
      setLoading(true);
      const ncmsServer = await fetchJSON(`ncms/list`, null, {
        filter: { fields: ["id"] },
      });

      const ids = ncmsServer.map((n) => n.id);
      const ncmsNew = rows.filter((n) => !ids.includes(n.codigo));
      setRows(ncmsNew);
      setFiltrado(true);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onSave = async () => {
    try {
      setLoading(true);
      let erros = [];
      for (const n of rows) {
        try {
          checkRequiredFields(n, columnsAll);
          const ncm = {
            id: n.codigo,
            ex: n.ex,
            tipo: n.tipo,
            descricao: n.descricao,
            nacional: n.nacionalfederal,
            importado: n.importadosfederal,
            estadual: n.estadual,
            municipal: n.municipal,
          };
          await save("ncms", ncm);
        } catch (err) {
          n.erro =
            err.body && err.body.error ? err.body.error.message : err.message;
          erros = rows.slice(rows.indexOf(n));
          break;
        }
      }

      setRows(erros);
      if (erros.length === 0) {
        setFiltrado(false);
      } else {
        tableRef.current.onToggleDetailPanel(
          [0],
          tableRef.current.props.detailPanel[0].render
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Table
        tableRef={tableRef}
        title="Importar NCM"
        components={{
          Container: (innerProps) => (
            <Paper
              {...innerProps}
              className={props.containerClassName}
              elevation={0}
            />
          ),
        }}
        columns={columns}
        data={rows}
      />
      <CardActions>
        <Button variant="contained" component="label" color="primary">
          <GetAppIcon />
          <span>Selecionar arquivo</span>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={onFileAdded}
            value={""}
            accept=".csv,.tsv,.txt"
          />
        </Button>
        {filtrado ? (
          <LoadingButton
            loading={loading}
            disabled={rows.length === 0}
            text="Salvar"
            variant="contained"
            component="label"
            color="primary"
            onClick={onSave}
          />
        ) : (
          <LoadingButton
            loading={loading}
            disabled={rows.length === 0}
            text="Filtrar"
            variant="contained"
            component="label"
            color="primary"
            onClick={onFilter}
          />
        )}
      </CardActions>
    </Card>
  );
}

export default withUser(ImportarNCM);
