import Decimal from "decimal.js";
import {
  calculateProfitMargin,
  calculateSellingPrice,
} from "../../common/util/util";

const sumTotal = (itens, field) =>
  itens.reduce((t, i) => t.plus(i[field] || 0), new Decimal(0)).valueOf();

function calculaTotais(form, formData) {
  const itensToSum = formData.itens
    .filter((i) => i && !i.deletar)
    .map((i) => ({ ...i }));

  const total_custo = sumTotal(itensToSum, "total");
  const itens = formData.itens.map((i) => ({
    ...i,
    porcentagem: i.deletar
      ? 0
      : new Decimal(i.total).dividedBy(total_custo).times(100).toFixed(0),
  }));

  form.batch(() => {
    form.change("itens", itens);
    form.change("total_custo", total_custo);
  });
}

export function calculosFichaTecnica(form, formData) {
  formData.itens = formData.itens || [];
  calculaTotais(form, formData);
}

export function parseDataFichaTecnica(record) {
  const itensA = record.itens.map((i, index) => {
    const { cod_prod, desc_prod, tam_emb_prod, preco_cst_prod } = i.produto;

    const estoque = Decimal(i.quantidade)
      .dividedBy(tam_emb_prod && tam_emb_prod > 0 ? tam_emb_prod : 1)
      .toFixed(4);

    const total = Decimal(preco_cst_prod).times(estoque).toFixed(4);

    return {
      ...i,
      index,
      cod_prod,
      desc_prod,
      tam_emb_prod,
      preco_cst_prod,
      estoque,
      total,
      produto: undefined,
    };
  });

  const total_custo = sumTotal(itensA, "total");
  const itensB = itensA.map((i) => ({
    ...i,
    porcentagem: new Decimal(i.total)
      .dividedBy(total_custo)
      .times(100)
      .toFixed(0),
  }));

  return {
    itens: itensB,
    total_custo,
  };
}

export function calculosAnaliseCusto(form, formData, nameValueChange) {
  const margemChanged = nameValueChange && nameValueChange.includes(".margem");

  for (let i = 0; i < formData.produtos.length; i++) {
    const prod = formData.produtos[i];
    if (margemChanged) {
      prod.venda = calculateSellingPrice(prod.margem || 0, prod.custo);
    } else {
      prod.margem = calculateProfitMargin(prod.venda || 0, prod.custo);
    }
  }

  form.batch(() => {
    const produtos = formData.produtos.map((i) => ({ ...i }));
    form.change("produtos", produtos);
  });
}

export function parseDataAnaliseCusto(data) {
  const produtos = data.produtos.map((i, index) => {
    const margem_atual = calculateProfitMargin(
      i.preco_vnd_prod,
      i.preco_cst_prod
    );

    return {
      ...i,
      index,
      margem_atual,
      margem: margem_atual,
      venda: calculateSellingPrice(margem_atual, i.custo),
    };
  });

  return {
    lojaId: data.lojaId,
    produtos,
  };
}
