import React from "react";
import { MenuItemLink } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    maxWidth: "240px",
    marginTop: "8px",
    marginBottom: "8px",
  },
  selected: {
    background: "#DDD",
    borderTopRightRadius: "9999px",
    borderBottomRightRadius: "9999px",
  },
}));

const MenuItem = (props) => {
  const classes = useStyles();
  const { hash } = window.location;
  const isSelected =
    hash === `#${props.to}` || hash.startsWith(`#${props.to}?`);
  return (
    <MenuItemLink
      {...props}
      className={`${props.itemRoot ? classes.menuItem : ""} ${
        isSelected ? classes.selected : ""
      }`}
    />
  );
};

export default MenuItem;
