import React from "react";
import {
  Datagrid,
  TextField,
  NumberField,
  FormDataConsumer,
  FunctionField,
} from "react-admin";
import { keyBy } from "lodash";
import useChangeCalc from "./useChangeCalc";
import { Typography } from "@mui/material";
import DatagridEmpty from "../../common/form/Datagrid/DatagridEmpty";
import DataGridRemoveButton from "../../common/form/DataGridRemoveButton";
import FichaTecnicaNovo from "./FichaTecnicaNovo";
import RatingField from "./RatingField";

export default function FichaTecnicaGrid({ loaded, formData }) {
  const changeCalc = useChangeCalc();
  const data = keyBy(formData.itens, "index");
  const ids = formData.itens.map(({ index }) => index);

  const handleRemoveItem = (source, newValue) => {
    const event = {
      target: {
        name: source,
        value: newValue,
      },
    };
    changeCalc(event);
  };

  return (
    <>
      <FichaTecnicaNovo formData={formData} />
      <Typography variant="h5" gutterBottom sx={{ mt: 4, ml: 1 }}>
        Lista de Ingredientes
      </Typography>
      <Datagrid
        loaded={loaded}
        data={data}
        ids={ids}
        currentSort={{ field: "index", order: "ASC" }}
        fullWidth
        empty={<DatagridEmpty message="Nenhum ingrediente na ficha técnica" />}
      >
        <FunctionField render={(record) => record.index + 1} label="Item" />
        <TextField source="cod_prod" label="Código" />
        <TextField source="desc_prod" label="Descrição" />
        <NumberField source="tam_emb_prod" label="Tamanho" />
        <NumberField source="preco_cst_prod" label="R$ Custo" />
        <NumberField source="quantidade" label="Quantidade" />
        <NumberField source="estoque" label="Estoque Usado" />
        <NumberField source="total" label="R$ Total" />
        <RatingField source="porcentagem" label="%" />

        <FormDataConsumer>
          {({ formData, record: itemData }) => (
            <DataGridRemoveButton
              formData={formData}
              record={itemData}
              arrayName="itens"
              onClick={handleRemoveItem}
            />
          )}
        </FormDataConsumer>
      </Datagrid>
    </>
  );
}
