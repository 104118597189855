import React from "react";
import {
  Datagrid,
  List,
  NumberField,
  TextField,
  BooleanField,
  EditButton,
} from "react-admin";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import CNPJCPFField from "../../common/form/CNPJCPFField";
import LojaFilter from "./LojaFilter";
import LojaConvidarButton from "./LojaConvidarButton";

const Acoes = ({ record }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  if (!record) return null;

  const handleClose = () => setAnchorEl(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  return (
    <div>
      <Button onClick={handleClick}>
        <MoreVertIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <EditButton label="Editar" record={record} />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LojaConvidarButton lojaId={record.id} />
        </MenuItem>
      </Menu>
    </div>
  );
};

const LojaList = (props) => (
  <List {...props} filters={<LojaFilter />} exporter={false}>
    <Datagrid>
      <NumberField source="id" label="Id" />
      <CNPJCPFField source="cnpj" label="CNPJ" />
      <TextField source="uf" label="UF" />
      <TextField source="razao_social" label="Razão social" />
      <TextField source="nome_fantasia" label="Nome fantasia" />
      <BooleanField source="ativo" label="Ativo" />
      <Acoes />
    </Datagrid>
  </List>
);

export default LojaList;
