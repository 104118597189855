import React, { useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import LoadingButton from "../../common/LoadingButton";
import { cleanCache, fetchJSON } from "../../dataProvider";
import { HttpError, useNotify, useRefresh } from "react-admin";
import { withUser } from "../../common/util/hocs";

const MovimentoCancelaButton = ({ movimento, permissao }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  if (
    movimento.deletado_em ||
    !permissao.caixa_cancelar_venda ||
    !permissao.parceiroId
  ) {
    return null;
  }

  const emitirNFCe = async () => {
    try {
      setLoading(true);

      await fetchJSON("Movimentos/cancela", {
        method: "POST",
        body: {
          lojaId: movimento.lojaId,
          movimentoId: movimento.id,
          funcionarioId: permissao.parceiroId,
          motivo: "cancelamento pela nuvem",
        },
      });

      cleanCache("Movimentos");
      refresh();
    } catch (err) {
      console.log(JSON.stringify(err));

      if (err instanceof HttpError) {
        if (err.status === 400) {
          notify(err.message);
          return;
        }
      }

      notify("Ocorreu um erro ao cancelar o movimento");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      color="error"
      text="Cancelar"
      loading={loading}
      icon={<ClearIcon />}
      onClick={emitirNFCe}
    />
  );
};

export default withUser(MovimentoCancelaButton);
