import React from "react";
import { SelectInput } from "react-admin";

export const OPCOES = [
  { id: null, name: "Nenhum" },

  { id: "SmartTEF", name: "PDV Mobile" },

  { id: "0", name: "SiTEF Genérico" },
  { id: "2", name: "SiTEF Débito" },
  { id: "3", name: "SiTEF Crédito" },
  { id: "122", name: "PIX" },

  { id: "PIX", name: "Mercado Pago PIX" },
];

const optionRenderer = (choice) =>
  (choice.id ? `${choice.id} - ` : "") + choice.name;

const SitefFuncaoInput = (props) => {
  return (
    <SelectInput {...props} choices={OPCOES} optionText={optionRenderer} />
  );
};

export default SitefFuncaoInput;
