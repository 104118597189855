import React from "react";
import {
  FormDataConsumer,
  TabbedForm,
  FormTab,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  RadioButtonGroupInput,
  required,
  email,
  Labeled,
  NumberInput,
  minValue,
} from "react-admin";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import { IdDates } from "../../common/form/ConditionalInputs";
import CepInput from "../../common/form/CEPInput";
import RegimeInput from "../../common/form/RegimeInput";
import LojaApp from "./LojaApp";
import { withUser } from "../../common/util/hocs";
import {
  CPFCNPJInput,
  EnderecoNumeroInput,
  TelefoneInput,
} from "../../common/form/NumberMaskInput";
import { isValidCNPJCPF } from "../../common/util/validations";
import UFInput from "../../common/form/UFInput";
import NumberInputSimple from "../../common/form/NumberInputSimple";
import { Typography } from "@mui/material";
import {
  validateInscricaoEstadual,
  validateSaboresQtd,
} from "../../common/util/ra-validations";
import ProdutoLazyReferenceInput from "../produto/ProdutoLazyReferenceInput";

const validateReq = [required()];
const validateCNPJCPF = [required(), isValidCNPJCPF];
const validateEmail = [email()];
const validateIE = [validateInscricaoEstadual];
const validateSabores = [validateSaboresQtd];
const validateRS = [required(), minValue(0)];

const useStyles = makeStyles({
  inline: {
    display: "inline-block",
    marginRight: 32,
  },
  publicarLojaFisica: {
    width: "18em",
  },
  cep: {
    width: "15em",
    display: "inline-block",
  },
  logradouro: {
    width: "43em",
    display: "inline-block",
    marginRight: 32,
  },
  numero: {
    width: "7em",
    display: "inline-block",
  },
  uf: {
    width: "6em",
    display: "inline-block",
    marginRight: 32,
  },
  referenceMany: {
    paddingTop: "5em",
  },
  gridLabel: {
    margin: 0,
    paddingTop: "2em",
  },
  grid: {
    borderWidth: 2,
    borderRadius: 10,
    borderColor: "#eeeeee",
    borderStyle: "double",
    backgroundColor: "#fafafa",
  },
});

const initialValues = {
  uuid: uuidv4(),
};

function LojaForm(props) {
  const { permissao, record } = props;
  const classes = useStyles();
  const redirect = window.location.href.includes("loja_perfil")
    ? "edit"
    : "list";

  const disableWhatsapp = record.whatsapp && !permissao.ADMIN;

  return (
    <TabbedForm {...props} initialValues={initialValues} redirect={redirect}>
      <FormTab label="Dados da loja">
        <IdDates />

        <TextInput
          source="nome_fantasia"
          label="Nome fantasia"
          validate={validateReq}
          fullWidth
        />

        <TextInput
          source="telefone"
          label="Telefone"
          formClassName={classes.inline}
          InputProps={{
            inputComponent: TelefoneInput,
            startAdornment: (
              <InputAdornment position="start">+55</InputAdornment>
            ),
          }}
        />
        <TextInput
          source="whatsapp"
          label="WhatsApp"
          disabled={disableWhatsapp}
          formClassName={classes.inline}
          InputProps={{
            inputComponent: TelefoneInput,
            startAdornment: (
              <InputAdornment position="start">+55</InputAdornment>
            ),
          }}
        />

        {permissao.ADMIN && (
          <TextInput
            label="Email do Usuário"
            source="user_email"
            type="email"
            formClassName={classes.inline}
            validate={validateEmail}
          />
        )}
        <div />

        <CepInput source="cep" label="CEP" />
        <TextInput
          source="logradouro"
          label="Logradouro"
          formClassName={classes.logradouro}
          fullWidth
        />
        <TextInput
          source="numero"
          label="Número"
          formClassName={classes.numero}
          fullWidth
          InputProps={{
            inputComponent: EnderecoNumeroInput,
          }}
        />
        <div />

        <TextInput source="complemento" label="Complemento" fullWidth />

        <TextInput
          source="bairro"
          label="Bairro"
          formClassName={classes.inline}
        />
        <TextInput
          source="cidade"
          label="Cidade"
          formClassName={classes.inline}
        />
        <UFInput source="uf" label="UF" formClassName={classes.uf} fullWidth />
        <TextInput
          source="codigo_municipio"
          label="Código Municipio"
          formClassName={classes.inline}
        />

        <BooleanInput
          label="Publica menú para loja física"
          source="publicarLojaFisica"
          formClassName={classes.publicarLojaFisica}
          fullWidth
        />
      </FormTab>

      <FormTab label="Fiscal">
        <TextInput
          source="razao_social"
          label="Razão social"
          disabled={true}
          fullWidth
        />

        <TextInput
          disabled={true}
          source="cnpj"
          label="CNPJ"
          InputProps={{
            inputComponent: CPFCNPJInput,
          }}
          formClassName={classes.inline}
        />

        <RegimeInput
          source="regime_tributario"
          label="Regime tributário"
          validate={validateReq}
          initialValue={1}
          formClassName={classes.inline}
        />
        <div />

        <TextInput
          source="inscricao_municipal"
          label="Inscrição Municipal"
          formClassName={classes.inline}
        />

        <TextInput
          source="inscricao_estadual"
          label="Inscrição Estadual"
          formClassName={classes.inline}
          validate={validateIE}
        />
        <div />

        <TextInput
          source="cscId"
          label="NFCe CSC Id"
          formClassName={classes.inline}
        />

        <TextInput
          source="csc"
          label="NFCe CSC"
          formClassName={classes.inline}
        />
        <div />

        <TextInput
          source="email_contador"
          label="Email contador"
          formClassName={classes.inline}
        />

        <NumberInputSimple
          source="nfe_cred_sn_alq"
          label="NFe Aliquota de Crédito SN"
          InputProps={{
            endAdornment: <InputAdornment sx={{ mt: 3 }}>%</InputAdornment>,
          }}
        />

        <TextInput
          source="nfe_informacao_adicional"
          label="NFe informação adicional"
          multiline
          fullWidth
        />
      </FormTab>

      <FormTab label="App">
        <FormDataConsumer>
          {(formDataProps) => (
            <LojaApp {...formDataProps} permissao={permissao} />
          )}
        </FormDataConsumer>
      </FormTab>

      <FormTab label="Modo de Operação">
        <RadioButtonGroupInput
          row={true}
          translateChoice={false}
          source="comanda_nome"
          label="Modo de operação"
          choices={[
            {
              id: "COMANDA",
              name: "Comanda",
            },
            {
              id: "MESA",
              name: "Mesa",
            },
          ]}
          fullWidth
        />
        <NumberInput
          source="taxa_servico"
          label="Taxa de Serviço do Garçom"
          validate={validateRS}
          initialValue={0}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />

        <ProdutoLazyReferenceInput
          source={"produtoCouvertId"}
          nameSource={"couvert"}
          label="Produto Couvert"
          allowEmpty
          fullWidth
        />
      </FormTab>

      <FormTab label="Tabelas de preço">
        <RadioButtonGroupInput
          row={false}
          translateChoice={false}
          source="pizzaPreco"
          choices={[
            {
              id: "MAXIMO",
              name: "Preço da pizza é o preço do sabor mais caro",
            },
            {
              id: "MEDIA",
              name: "Preço da pizza é a média do preço dos sabores",
            },
          ]}
          fullWidth
        />
        <ArrayInput source="tabelasPreco" label="" fullWidth>
          <SimpleFormIterator
            disableReordering
            TransitionProps={{ enter: false, exit: false }}
            disableRemove
          >
            <TextInput
              label="nome"
              source="nome"
              validate={validateReq}
              formClassName={classes.inline}
            />

            <TextInput
              label="Sabores"
              source="config"
              initialValue="1,2,3,4"
              validate={validateSabores}
              formClassName={classes.inline}
            />

            <BooleanInput
              source="deletar"
              label="Excluir"
              formClassName={classes.inline}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      {permissao.ADMIN && (
        <FormTab label="Admin">
          <IdDates />

          {record.id && (
            <Labeled label="UUID" fullWidth>
              <Typography>{record.uuid}</Typography>
            </Labeled>
          )}

          <BooleanInput source="ativo" label="Ativo" initialValue={true} />

          <TextInput
            source="cnpj"
            label="CNPJ"
            validate={validateCNPJCPF}
            InputProps={{
              inputComponent: CPFCNPJInput,
            }}
          />

          <TextInput
            source="razao_social"
            label="Razão social"
            validate={validateReq}
            fullWidth
          />
          <TextInput
            source="nome_fantasia"
            label="Nome fantasia"
            validate={validateReq}
            fullWidth
          />
          <TextInput
            label="Email do Usuário"
            source="user_email"
            type="email"
            validate={validateEmail}
          />
        </FormTab>
      )}
    </TabbedForm>
  );
}

export default withUser(LojaForm);
