import { makeStyles } from "@material-ui/core/styles";
import Decimal from "decimal.js";
import React, { useCallback, useState } from "react";
import {
  useDataProvider,
  useNotify,
  Button,
  minValue,
  maxValue,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { Grid, InputAdornment, Typography } from "@mui/material";
import { useForm, useFormState } from "react-final-form";
import { SmallCircularProgress } from "../../common/CircularProgress";
import NumberInputReadonly from "../../common/form/NumberInputReadonly";
import NumberInputSimple from "../../common/form/NumberInputSimple";
import { validateDecimalPlaces } from "../../common/util/ra-validations";
import ProdutoLazyReferenceInput from "../produto/ProdutoLazyReferenceInput";
import { calculosFichaTecnica } from "./calculos";

const validateQuantidade = [
  minValue(0.001),
  maxValue(99999999.999),
  validateDecimalPlaces(3),
];

const useStyles = makeStyles({
  produto: {
    width: "20em",
    marginRight: "1em",
  },
  numberInput: {
    width: "8em",
    marginRight: "1em",
  },
  total: {
    width: "7em",
    marginRight: "1em",
  },
});

const ButtonAdd = (props) => {
  const form = useForm();
  const formState = useFormState();
  const notify = useNotify();

  const onAddItem = () => {
    const formData = formState.values;
    const { itens, itemNovo } = formData;
    const { produtoId, cod_prod, desc_prod, quantidade } = itemNovo || {};

    if (!produtoId) {
      notify(`Preencha o produto!`);
      return;
    }

    if (!cod_prod) {
      notify(`Preencha o codigo!`);
      return;
    }

    if (!desc_prod) {
      notify(`Preencha a descrição!`);
      return;
    }

    if (quantidade <= 0) {
      notify(`Preencha a quantidade positiva!`);
      return;
    }

    formData.itemNovo = null;
    formData.itens = [
      ...itens,
      {
        ...itemNovo,
        index: itens.length,
      },
    ];

    calculosFichaTecnica(form, formData);
  };

  return (
    <Button onClick={onAddItem} label="Adicionar" variant="outlined">
      <AddIcon />
    </Button>
  );
};

export default function FichaTecnicaNovo({ formData }) {
  const [loading, setLoading] = useState(false);
  const form = useForm();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const getSource = useCallback((field) => `itemNovo.${field}`, []);

  const handleChangeProduct = async (productId) => {
    try {
      setLoading(true);
      const { data: produto } = await dataProvider.getOne("produtos", {
        id: productId,
      });

      form.batch(() => {
        form.change(getSource("cod_prod"), produto.cod_prod);
        form.change(getSource("desc_prod"), produto.desc_prod);
        form.change(getSource("tam_emb_prod"), produto.tam_emb_prod);
        form.change(getSource("preco_cst_prod"), produto.preco_cst_prod);
      });
    } catch (error) {
      notify("Erro ao carregar os dados do produto.", "warning");
      console.log(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  const mergeValue = (object, field, value) => ({ ...object, [field]: value });

  // const handleChangeValorUnit = (event) =>
  //   calculaItemTotal(
  //     mergeValue(formData.itemNovo, "valor_unitario", event.target.value)
  //   );

  const handleChangeQuantidade = (event) =>
    calculaItemTotal(
      mergeValue(formData.itemNovo, "quantidade", event.target.value)
    );

  const calculaItemTotal = (itemNovo) => {
    const { tam_emb_prod, preco_cst_prod, quantidade } = itemNovo;

    const estoque = Decimal(quantidade || 0)
      .dividedBy(tam_emb_prod && tam_emb_prod > 0 ? tam_emb_prod : 1)
      .toFixed(4);

    const total = Decimal(preco_cst_prod).times(estoque).toFixed(4);

    form.batch(() => {
      form.change(getSource("total"), total);
      form.change(getSource("estoque"), estoque);
    });
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom sx={{ mt: 4, ml: 1 }}>
          Novo Ingrediente
        </Typography>
      </Grid>
      <Grid item>
        <ProdutoLazyReferenceInput
          label="Produto"
          source={getSource("produtoId")}
          nameSource={getSource("produto")}
          className={classes.produto}
          fullWidth
          onChange={handleChangeProduct}
        />
      </Grid>
      {loading && (
        <Grid item>
          <SmallCircularProgress />
        </Grid>
      )}
      <Grid item>
        <NumberInputReadonly
          className={classes.total}
          label="Tamanho"
          source={getSource("tam_emb_prod")}
        />
      </Grid>
      <Grid item>
        <NumberInputReadonly
          className={classes.total}
          label="R$ Custo Unit."
          source={getSource("preco_cst_prod")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <NumberInputSimple
          className={classes.numberInput}
          label="Quantidade"
          source={getSource("quantidade")}
          min={1}
          disabled={loading}
          onChange={handleChangeQuantidade}
          validate={validateQuantidade}
        />
      </Grid>
      <Grid item>
        <NumberInputReadonly
          className={classes.total}
          label="Estoque Usado"
          source={getSource("estoque")}
        />
      </Grid>
      <Grid item>
        <NumberInputReadonly
          className={classes.total}
          label="Total"
          source={getSource("total")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item sx={{ mr: 5, mb: 2 }}>
        <ButtonAdd />
      </Grid>
    </Grid>
  );
}
